import React, { useRef, useState } from 'react'
import { faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faCheckCircle, faUndo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

const eleven = suffix => new Array(11).fill().map((v, i) => `${i + 1}${suffix}`)
const hours = ['12AM', ...eleven('AM'), '12PM', ...eleven('PM')]

const AffidavitInput = ({ isciCodes, controls = {}, onIsciChange, receivedPlaybacks = [] }) => {
  const isciList = Object.keys(isciCodes).sort()
  const [currentIsci, setCurrentIsci] = useState(isciList[0])

  const [magic] = useState({ lastIsciList: '' })
  if (magic.lastIsciList !== isciList.join(', ')) {
    magic.lastIsciList = isciList.join(', ')
    if (typeof onIsciChange === 'function') onIsciChange(isciList[0])
  }

  const [cursor, setCursor] = useState({
    area: 'codes', // weekdays or codes
    index: 0,
    detail: 0 // left-right position
  })
  const hasCursor = (area, index, detail = null) => cursor.area === area && cursor.index === index && (detail == null || cursor.detail === detail)

  const containerRef = useRef()

  const moveCursor = (dir) => {
    const handleMove = (newCursor) => {
      if (containerRef.current) {
        const elem = containerRef.current.querySelector(
          `[data-cursor="${newCursor.area}-${newCursor.index}-${newCursor.detail}"], [data-cursor="${newCursor.area}-${newCursor.index}"]`
        )
        if (elem) elem.focus()
        if (elem && elem.tagName === 'INPUT') setTimeout(() => elem.select(), 0)
      }

      return newCursor
    }

    if (dir === 'up') {
      setCursor(existingCursor => handleMove({
        ...existingCursor,
        index: Math.max(existingCursor.index - 1, 0)
      }))
    }
    if (dir === 'down') {
      setCursor(existingCursor => handleMove({
        ...existingCursor,
        index: Math.min(existingCursor.index + 1, existingCursor.area === 'weekdays' ? 7 : isciList.length - 1)
      }))
    }

    if (dir === 'right') {
      setCursor(cursor => handleMove({
        ...cursor,
        area: 'weekdays',
        detail: cursor.area === 'codes' ? 0 : Math.min(cursor.detail + 1, 3),
        index: Math.min(cursor.index, 7)
      }))
    }

    if (dir === 'left') {
      setCursor(existingCursor => handleMove({
        ...existingCursor,
        area: existingCursor.detail ? 'weekdays' : 'codes',
        detail: Math.max(existingCursor.detail - 1, 0),
        index: Math.min(cursor.index, existingCursor.detail ? 7 : isciList.length - 1)
      }))
    }

    const match = dir.match(/^(weekdays|codes)-(\d+)-(\d+)$/)
    if (match) {
      setCursor(cursor => handleMove({
        ...cursor,
        area: match[1],
        index: Number(match[2]),
        detail: Number(match[3])
      }))
    }
  }

  const handleNavigate = (event) => {
    if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) event.preventDefault()

    if (event.shiftKey || ['+', '-'].includes(event.key)) {
      if (cursor.area === 'weekdays' && cursor.detail < 2 && ['ArrowDown', 'ArrowUp', '+', '-'].includes(event.key)) {
        event.preventDefault()

        const { index, detail } = cursor

        const num = dailyInputs[index][detail]
        const clamped = Math.min(Math.max(Number(num) + (['ArrowUp', '+'].includes(event.key) ? 1 : -1), 0), detail ? 59 : 12)
        const str = detail ? String(clamped).padStart(2, '0') : String(clamped)

        setDailyInputs(inputs => {
          const newInputs = [...inputs]
          newInputs[index] = [...newInputs[index]]
          newInputs[index][detail] = str
          return newInputs
        })

        setTimeout(() => event.target.select(), 0)
      }

      return
    }

    if (event.key === 'ArrowUp') moveCursor('up')
    if (event.key === 'ArrowDown') moveCursor('down')
    if (event.key === 'ArrowLeft') moveCursor('left')
    if (event.key === 'ArrowRight') moveCursor('right')
  }

  const handleAutoSelect = (index, detail) => (event) => {
    event.target.select()
    setCursor(cursor => ({ ...cursor, area: 'weekdays', index, detail }))
  }

  const defaultDailyInputs = [
    ['0', '00', 'AM'],
    ['0', '00', 'AM'],
    ['0', '00', 'AM'],
    ['0', '00', 'AM'],
    ['0', '00', 'AM'],
    ['0', '00', 'AM'],
    ['0', '00', 'AM']
  ]

  const [dailyInputs, setDailyInputs] = useState(defaultDailyInputs)
  const resetDailyInputs = () => setDailyInputs(defaultDailyInputs)
  const setDailyNumberInput = (index, detail) => (event) => {
    const cleaned = event.target.value.replace(/[^0-9]/g, '')

    let value = cleaned
    if (isNaN(cleaned) || cleaned < 0) value = detail ? '00' : '0'
    if (cleaned > (detail ? 59 : 12)) value = detail ? '59' : '12'
    if (cleaned === '' || cleaned === (detail ? '00' : '0')) {
      value = detail ? '00' : '0'
      setTimeout(() => event.target.select(), 0)
    }

    setDailyInputs(inputs => {
      const newInputs = [...inputs]
      newInputs[index] = [...inputs[index]]
      newInputs[index][detail] = value

      return newInputs
    })

    if (cleaned.length >= 2) moveCursor('right')
  }
  const setDailySuffixInput = (index, detail, value) => (event) => {
    setDailyInputs(inputs => {
      const newInputs = [...inputs]
      newInputs[index] = [...inputs[index]]
      newInputs[index][2] = value

      return newInputs
    })

    setCursor(cursor => ({ ...cursor, area: 'weekdays', index, detail }))
  }

  const weekdayCursors = (
    <>
      <div className='affidavits-desktop__weekday-cursor-top'>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      <div className='affidavits-desktop__weekday-cursor-bottom'>
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </>
  )

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  // const [playbacksEntered, setPlaybacksEntered] = useState()
  const dispatch = useDispatch()
  const playbacksEntered = useSelector(state => state.affidavits.playbacks)
  const setPlaybacksEntered = (playbacks) => dispatch({ type: 'affidavits.setPlaybacks', playbacks })

  const playbacks = [...receivedPlaybacks, ...playbacksEntered]

  if (playbacksEntered.length && !isciCodes[currentIsci]) setTimeout(() => setPlaybacksEntered([]))

  const addPlayback = (index, preferredSuffix) => (event) => {
    if (!preferredSuffix) return moveCursor('right')

    const randomPool = Array.from(window.crypto.getRandomValues(new Uint32Array(4))).map(n => n.toString(16).padStart(8, '0')).join('')
    const id = `${randomPool.slice(0, 8)}-${randomPool.slice(8, 12)}-${randomPool.slice(12, 16)}-${randomPool.slice(16, 20)}-${randomPool.slice(20, 32)}`

    const isci = currentIsci

    const dailyInput = index < 8 ? dailyInputs[index] : ['12', '00', 'AM']

    const [hour, minute] = dailyInput.slice(0, 2).map(Number)
    if (Number(hour) === 0) return

    const suffix = preferredSuffix || dailyInput[2]

    const day = days[index]
    const time = `${hour}:${String(minute).padStart(2, '0')} ${suffix}`
    const label = `${day} ${hour}:${String(minute).padStart(2, '0')} ${suffix}`

    setPlaybacksEntered(playbacks => [...playbacks, { id, isci, hour, minute, suffix, day, time, label, hidden: index > 7 }])

    setDailyInputs(inputs => {
      const newInputs = [...inputs]
      if (index < 8) newInputs[index] = [...defaultDailyInputs[index]]
      return newInputs
    })

    if (index < 8) moveCursor(`weekdays-${index}-0`)
  }

  const handleInputKeydown = (index) => (event) => {
    if (event.key === 'Enter') addPlayback(index)(event)
    handleNavigate(event)
  }

  const filteredPlaybacks = playbacks
    .filter(playback => !playback.hidden)
    .filter(playback => playback.isci === currentIsci)
    .sort((a, b) => (
      days.indexOf(a.day) - days.indexOf(b.day) ||
      hours.indexOf(`${a.hour}${a.suffix}`) - hours.indexOf(`${b.hour}${b.suffix}`) ||
      a.minute - b.minute
    ))

  const remainingPlaybacks = {}
  isciList.forEach(isci => {
    const remainingPerDay = days.map((day, index) => {
      return Math.max(isciCodes[isci].runCounts[index] - playbacks.filter(playback => playback.isci === isci && playback.day === day).length, 0)
    })

    const total = remainingPerDay.reduce((a, b) => a + b)

    remainingPlaybacks[isci] = {
      days: remainingPerDay,
      total
    }
  })

  // const sortedIsciList = isciList.sort((a, b) => {
  //   return (Boolean(remainingPlaybacks[b].total) - Boolean(remainingPlaybacks[a].total)) || a.localeCompare(b)
  // })

  const sortedIsciList = isciList.sort((a, b) => {
    return (
      Boolean(playbacks.filter(playback => playback.isci === a).length) - Boolean(playbacks.filter(playback => playback.isci === b).length)
    ) || a.localeCompare(b)
  })

  const currentRemaining = remainingPlaybacks[currentIsci] || { days: [0, 0, 0, 0, 0, 0, 0], total: 0 }

  controls.getData = () => {
    return {
      playbacks: playbacks.filter(playback => !playback.hidden),
      remainingPlaybacks
    }
  }

  return (
    <div className='affidavits-desktop' ref={containerRef}>
      <div className='affidavits-desktop__codes'>
        {
          sortedIsciList.map((code, index) => {
            return (
              <div className='affidavits-desktop__code-wrapper' key={code}>
                <button
                  className={classNames('button affidavits-desktop__code', {
                    'is-primary': currentIsci === code
                  })}
                  onKeyDown={handleNavigate}
                  onClick={() => {
                    setCurrentIsci(code)
                    resetDailyInputs()
                    moveCursor('weekdays-0-0')
                    if (typeof onIsciChange === 'function') onIsciChange(code)
                  }}
                  data-cursor={`codes-${index}`}
                >
                  {code}
                  {
                    playbacks.filter(playback => playback.isci === code).length
                      ? (
                        <span className='affidavits-desktop__code-done-icon'>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                        )
                      : undefined
                  }
                </button>
                {hasCursor('codes', index)
                  ? (
                    <>
                      <div className='affidavits-desktop__code-cursor-left'>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                      <div className='affidavits-desktop__code-cursor-right'>
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </div>
                    </>
                    )
                  : undefined}
              </div>
            )
          })
        }
      </div>
      <div className='affidavits-desktop__weekdays'>
        {
          days.map((day, index) => {
            return (
              <div
                className={classNames('affidavits-desktop__weekday', {
                  'affidavits-desktop__weekday--highlighted': Boolean(currentRemaining.days[index])
                })}
                key={day}
              >
                <div className='affidavits-desktop__weekday-name'>
                  <div className='affidavits-desktop__weekday-name-actual'>
                    {day}
                  </div>
                  <div className='affidavits-desktop__weekday-name-remaining'>
                    {currentRemaining.days[index]
                      ? (
                          currentRemaining.days[index] === 1
                            ? `${currentRemaining.days[index]} Spot`
                            : `${currentRemaining.days[index]} Spots`
                        )
                      : 'Done'}
                  </div>
                </div>
                <div className='affidavits-desktop__hour-input'>
                  <input
                    className={classNames('input affidavits-desktop__hour-input-actual', {
                      'is-danger': (hasCursor('weekdays', index, 2) || hasCursor('weekdays', index, 3)) && dailyInputs[index][0] === '0'
                    })}
                    onFocus={handleAutoSelect(index, 0)} data-cursor={`weekdays-${index}-0`}
                    onKeyDown={handleInputKeydown(index)}
                    maxLength={2}
                    value={dailyInputs[index][0]}
                    onChange={setDailyNumberInput(index, 0)}
                  />
                  {hasCursor('weekdays', index, 0)
                    ? weekdayCursors
                    : undefined}
                </div>
                <div className='affidavits-desktop__colon'>
                  :
                </div>
                <div className='affidavits-desktop__minute-input'>
                  <input
                    className='input affidavits-desktop__minute-input-actual'
                    onFocus={handleAutoSelect(index, 1)} data-cursor={`weekdays-${index}-1`}
                    onKeyDown={handleInputKeydown(index)}
                    maxLength={2}
                    value={dailyInputs[index][1]}
                    onChange={setDailyNumberInput(index, 1)}
                  />
                  {hasCursor('weekdays', index, 1)
                    ? weekdayCursors
                    : undefined}
                </div>
                <div
                  className='affidavits-desktop__am'
                >
                  <button
                    className={classNames('button affidavits-desktop__time-button', {
                      'is-primary': dailyInputs[index][2] === 'AM'
                    })}
                    onFocus={setDailySuffixInput(index, 2, 'AM')} data-cursor={`weekdays-${index}-2`}
                    onKeyDown={handleNavigate}
                    onClick={addPlayback(index, 'AM')}
                  >
                    AM
                  </button>
                  {hasCursor('weekdays', index, 2)
                    ? weekdayCursors
                    : undefined}
                </div>
                <div
                  className='affidavits-desktop__pm'
                >
                  <button
                    className={classNames('button affidavits-desktop__time-button', {
                      'is-primary': dailyInputs[index][2] === 'PM'
                    })}
                    onFocus={setDailySuffixInput(index, 3, 'PM')} data-cursor={`weekdays-${index}-3`}
                    onKeyDown={handleNavigate}
                    onClick={addPlayback(index, 'PM')}
                  >
                    PM
                  </button>
                  {hasCursor('weekdays', index, 3)
                    ? weekdayCursors
                    : undefined}
                </div>
              </div>
            )
          })
        }
        <div className='affidavits-desktop__weekday'>
          <div className='affidavits-desktop__weekday-done'>
            <button
              className={classNames('button affidavits-desktop__weekday-done-button is-fullwidth', {
                'is-primary': playbacks.filter(playback => playback.isci === currentIsci).length
              })}
              onKeyDown={handleNavigate}
              data-cursor='weekdays-7'
              // disabled={currentRemaining.total}
              onClick={() => {
                addPlayback(8, 'AM')()
                setCurrentIsci(sortedIsciList.filter(isci => isci !== currentIsci)[0])
                resetDailyInputs()
                moveCursor('weekdays-0-0')
                if (typeof onIsciChange === 'function') onIsciChange(sortedIsciList[0])
              }}
            >
              <span className='icon'>
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
              <span>
                Done
              </span>
            </button>
            {hasCursor('weekdays', 7)
              ? weekdayCursors
              : undefined}
          </div>
        </div>
      </div>
      <div className='affidavits-desktop__playbacks'>
        <div className='affidavits-desktop__playbacks-title'>
          <div className='affidavits-desktop__playbacks-title-actual'>
            Air Times Logged
          </div>
          <div className='affidavits-desktop__playbacks-title-isci'>
            For ISCI {currentIsci}
          </div>
        </div>
        <div className='affidavits-desktop__playbacks-list'>
          {
            filteredPlaybacks.map(playback => {
              const feedback = () => {
                const dayIndex = days.indexOf(playback.day)

                setDailyInputs(inputs => {
                  const newInputs = [...inputs]
                  newInputs[dayIndex] = [String(playback.hour), String(playback.minute).padStart(2, '0'), playback.suffix]
                  return newInputs
                })
                moveCursor(`weekdays-${dayIndex}-0`)

                setPlaybacksEntered(playbacks => playbacks.filter(p => p.id !== playback.id))
              }

              return (
                <div className='affidavits-desktop__playback' key={playback.id}>
                  <div className='affidavits-desktop__playback-label'>
                    <div className='affidavits-desktop__playback-time'>
                      {playback.time}
                    </div>
                    <div className='affidavits-desktop__playback-day'>
                      {playback.day}
                    </div>
                  </div>
                  <div className='affidavits-desktop__playback-controls'>
                    {
                      playback.time !== 'As Ordered'
                        ? (
                          <button className='button is-small' onClick={feedback}>
                            <span className='icon'>
                              <FontAwesomeIcon icon={faUndo} />
                            </span>
                          </button>
                          )
                        : undefined
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      {/* <div className='affidavits-desktop__playbacks'>
        <div className='affidavits-desktop__playbacks-title'>
          <div className='affidavits-desktop__playbacks-title-actual'>
            Playbacks Missed
          </div>
        </div>
        <div className='affidavits-desktop__playbacks-list'>
          {
            isciList.filter(isci => (
              remainingPlaybacks[isci].total
            )).map(isci => {
              const missing = remainingPlaybacks[isci].days
                .map((day, index) => (
                  day > 1
                    ? `${days[index]} x${day}`
                    : day ? days[index] : ''
                ))
                .filter(s => s !== '')
                .join(', ')

              return (
                <div className='affidavits-desktop__playback' key={isci}>
                  <div className='affidavits-desktop__playback-label'>
                    <div className='affidavits-desktop__playback-time'>
                      {isci}
                    </div>
                    <div className='affidavits-desktop__playback-day'>
                      {missing}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div> */}
    </div>
  )
}

export default AffidavitInput
