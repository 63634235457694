import { combineReducers } from 'redux'

import affidavits from './affidavits'
import commercials from './commercials'
import playerNFilters from './player-n-filters'
import pwreset from './pwreset'

const frontEndReducer = combineReducers({
  affidavits,
  commercials,
  playerNFilters,
  pwreset
})

export default frontEndReducer
