import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons'
import { faCheck, faTimes, faAngleLeft, faPlus, faMinus, faSquareFull, faRectangleLandscape, faBuilding, faUser, faAt, faPhone, faSignIn, faUserCircle } from '@fortawesome/pro-solid-svg-icons'
import { } from '@fortawesome/pro-regular-svg-icons'
import { faHandPeace, faSlidersV, faComments, faWaveform, faSearch, faAngleUp, faAngleRight, faCogs, faLaptop, faMusic, faStar, faCloudDownload, faTrashAlt, faCopy, faSyncAlt } from '@fortawesome/pro-light-svg-icons'

// Pages
import Home from './pages/Home'
import Explore from './pages/Explore'
import About from './pages/About'
import Contact from './pages/Contact'
import Login from './pages/Login'
import Affidavits from './pages/Affidavits'
import Commercials from './pages/Commercials'
import Settings from './pages/Settings'
import TermsOfService from './pages/TermsOfService'
import PrivacyPolicy from './pages/PrivacyPolicy'
import PasswordReset from './pages/PasswordReset'
import PasswordResetRequest from './pages/PasswordResetRequest'
import PasswordResetMessage from './pages/PasswordResetMessage'
import NotFound404 from './pages/NotFound404'
import ReallyNotFound404 from './pages/ReallyNotFound404'
import ProtectedRoute from './components/ProtectedRoute'

library.add(faCheck, faTimes, faAngleLeft, faAngleRight, faAngleUp, faPaperPlane, faPlus, faMinus, faSlidersV, faSquareFull, faSearch, faWaveform, faRectangleLandscape, faComments, faHandPeace, faBuilding, faUser, faAt, faPhone, faSignIn, faUserCircle, faCogs, faLaptop, faMusic, faStar, faCloudDownload, faTrashAlt, faCopy, faSyncAlt)

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path='/' exact render={props => <Home {...props} />} />
      <Route path='/explore' exact render={props => <Explore {...props} />} />
      <Route path='/about' exact render={props => <About {...props} />} />
      <Route path='/contact' exact render={props => <Contact {...props} />} />
      <Route path='/login' exact render={props => <Login {...props} />} />
      <ProtectedRoute path='/affidavits' component={Affidavits} />
      <ProtectedRoute path='/commercials' component={Commercials} />
      <ProtectedRoute path='/settings' component={Settings} mode='userOnly' />
      <Route path='/terms-of-service' exact render={props => <TermsOfService {...props} />} />
      <Route path='/privacy-policy' exact render={props => <PrivacyPolicy {...props} />} />
      <Route path='/password-reset' exact render={props => <PasswordReset {...props} />} />
      <Route path='/pwreset' exact render={props => <PasswordResetRequest {...props} />} />
      <Route path='/pwresetcomplete' exact render={props => <PasswordResetMessage {...props} />} />
      <Route path='/pwresetfail' exact render={props => <PasswordResetMessage {...props} />} />
      <Route path='/404' exact render={props => <NotFound404 {...props} />} />
      <Route path='/admin' exact render={props => <ReallyNotFound404 {...props} />} />
      <Route path='/wp_admin' exact render={props => <ReallyNotFound404 {...props} />} />
      <Redirect to='/404' />
    </Switch>
  </BrowserRouter>
)

export default App
