import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import useModal from './useModal'

const AffidavitMethodSelector = (props) => {
  const dispatch = useDispatch()
  const playbacks = useSelector(state => state.affidavits.playbacks)

  const [modal, showModal] = useModal()

  const handleBack = async () => {
    if (playbacks.length) {
      const response = await showModal(
        'WARNING',
        `If you leave this area, all your current progress on "${props.title}" will be lost. Are you sure you'd like to leave?`,
        [{
          text: 'Cancel',
          response: 'cancel',
          isCancel: true,
          className: 'modal-cancel'
        },
        {
          text: 'Leave',
          response: 'yes',
          className: 'submitButton',
          icon: ['fal', 'angle-right'],
          iconProps: {
            size: '2x'
          }
        }]
      )

      if (response !== 'yes') return
    }

    dispatch({ type: 'affidavits.clearWeek' })
    dispatch({ type: 'affidavits.clearPlaybacks' })
  }

  return (
    <div className='affidavit-method-selector'>
      <h1 className='title has-text-centered'>{props.title}</h1>
      <h2 className='subtitle has-text-centered'>{props.subtitle || 'Select Reporting Method'}</h2>
      <h2 className='subtitle week-selector__back-button mx-4 mt-6'>
        <a className='week-selector__back-link' onClick={handleBack}>
          <span className='icon'>
            <FontAwesomeIcon icon={faCaretLeft} />
          </span>
          <span>
            Back to Affidavit Week
          </span>
        </a>
      </h2>
      <div className='affidavit-method-selector__weeks mx-4 my-3'>
        <button className='affidavit-method-selector__method button is-primary' onClick={() => dispatch({ type: 'affidavits.setMethod', method: 'automatic' })}>
          <div className='affidavit-method-selector__method-title'>Automatic</div>
          <div className='affidavit-method-selector__method-notice'>All Spots Aired As Ordered</div>
        </button>
        <button className='affidavit-method-selector__method button is-primary' onClick={() => dispatch({ type: 'affidavits.setMethod', method: 'manual' })}>
          <div className='affidavit-method-selector__method-title'>Manual</div>
          <div className='affidavit-method-selector__method-notice'>Enter Specific Air Times</div>
        </button>
      </div>
      {modal}
    </div>
  )
}

export default AffidavitMethodSelector
