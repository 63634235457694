import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

const BackToTopSideButton = props => {
  const { watchTargetSelector } = props

  const goUp = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  const baseClasses = ['back-to-top', 'is-uppercase', 'is-family-secondary', 'is-slightly-bigger']

  const [hideButton, setHideButton] = useState(true)

  useEffect(() => {
    const showOnScroll = () => {
      const secondSlideOffset = document.querySelector(watchTargetSelector).getBoundingClientRect().top
      if (window.pageYOffset > secondSlideOffset) setHideButton(false)
      else setHideButton(true)
    }
    showOnScroll()
    window.addEventListener('scroll', showOnScroll)
    return () => window.removeEventListener('scroll', showOnScroll)
  }, []) // eslint-disable-line

  return (
    <>
      <button onClick={goUp} type='button' className={classNames(baseClasses, { hidden: hideButton })}><FontAwesomeIcon icon={['fal', 'angle-up']} size='3x' /></button>
    </>
  )
}

export default BackToTopSideButton
