import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'

import MainLayout from '../containers/MainLayout'
import FormWrapper from '../components/FormWrapper'
import FormInputField from '../components/FormInputField'
import FormTextarea from '../components/FormTextarea'
import SubmitButton from '../components/SubmitButton'
import FormErrorsList from '../components/FormErrorsList'
import FormStatus from '../components/FormStatus'
import Gradients from '../components/Home/Gradients'
import BackToTopSideButton from '../components/BackToTopSideButton'
import { useFrankentangel } from '../frankentangel'

const Contact = props => {
  const frankentangel = useFrankentangel()

  const submitFunction = async (values) => {
    await frankentangel.runAction('virtual', 'websitemail', {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      callLetters: values.companyOrLetters,
      phone: values.phone,
      message: values.message
    })

    return 'Your message has been submitted successfully. Thank you for contacting us.'
  }

  return (
    <MainLayout alignstart fullheight innerClasses={['home-body']}>
      <Helmet>
        <title>Contact Us - TAA Music</title>
      </Helmet>
      <Gradients />
      <div className='container'>
        <div className='columns is-vcentered contact-form-type'>
          <div className='column is-7'>
            <div className='contactFormWrapper'>
              <p className='contactFormWrapper__blue-form-msg is-family-secondary'>Let’s Make Some Beautiful Music Together</p>
              <div className='contactFormWrapper__inner-wrapper'>
                <h1 className='contactFormWrapper__form-title'>Contact Us</h1>
                <FormWrapper
                  fields={yup.object({
                    firstName: yup.string().max(128, 'Max 128 chars').required('Required'),
                    lastName: yup.string().max(128, 'Max 128 chars').required('Required'),
                    email: yup.string().email('Please provide a valid email address').required('Required'),
                    companyOrLetters: yup.string().required('Required').max(255).required('Required'),
                    phone: yup.string().max(255).matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, 'Invalid phone number'),
                    message: yup.string().max(15000)
                  })}
                  submit={submitFunction}
                >
                  <FormInputField name='firstName' label='FIRST NAME' />
                  <FormInputField name='lastName' label='LAST NAME' />
                  <FormInputField name='email' label='EMAIL ADDRESS' />
                  <FormInputField name='companyOrLetters' label='CALL LETTERS / COMPANY' />
                  <FormInputField
                    name='phone'
                    label='PHONE'
                    pattern='\(\d{3}\)[ ]?\d{3}[-]?\d{4}'
                    phoneType
                    type='tel'
                  />
                  <FormTextarea name='message' label='MESSAGE' />
                  <FormErrorsList />
                  <SubmitButton buttonText='SEND' classes='contactFormWrapper__submit' />
                  <FormStatus />
                </FormWrapper>
              </div>
            </div>
          </div>
          <BackToTopSideButton watchTargetSelector='.contactContent' />
          <div className='column is-5 contactContent'>
            <p className='contactContent__blue-paragraph'>Drop us a line about</p>
            <FontAwesomeIcon icon={['fal', 'sliders-v']} size='4x' className='gradient-icon-vertical' />
            <p className='contactContent__blue-paragraph'>Complimentary music production</p>
            <FontAwesomeIcon icon={['fal', 'search']} size='4x' className='gradient-icon-vertical' />
            <p className='contactContent__blue-paragraph'>Complimentary music search</p>
            <FontAwesomeIcon icon={['fal', 'waveform']} size='4x' className='gradient-icon-vertical' />
            <p className='contactContent__blue-paragraph'>Flexible subscriptions<br />or just to chat about music.</p>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Contact
