import { useDispatch } from 'react-redux'
import Button from './Button'

const AffidavitThankYouScreen = ({ user, week, account, playbacks }) => {
  const dispatch = useDispatch()

  const readableWeek = new Date(week?.actualDate?.replace(/-/g, '/') + ' 0:00').toLocaleString('en-us', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })

  return (
    <div className='affidavit-submit-screen'>
      <h1 className='title has-text-centered'>Thank You</h1>
      <h2 className='subtitle has-text-centered'>Certificate of Performance Submitted</h2>
      <div className='content affidavit-submit-screen__content'>
        <p>
          We have received your Certificate of Performance for the week of {readableWeek} for {account?.callsign} {account?.name}. Thank you for
          keeping your account in compliance.
        </p>
      </div>
      <div className='has-text-centered mt-6'>
        <Button classes='is-primary' onClick={() => dispatch({ type: 'affidavits.reset' })}>
          Return to Start
        </Button>
      </div>
    </div>
  )
}

export default AffidavitThankYouScreen
