import React, { useState } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useSession, useDocument } from '../frankentangel'
import LoadingScreen from '../pages/LoadingScreen'

const ProtectedRoute = props => {
  const [currentSession,, sessionLoading] = useSession()
  const [currentUser,, userLoading] = useDocument('users', Number(currentSession?.user) || 0)

  const [magic] = useState({ loadStarted: false, loadFinished: false })
  const [, setUpdate] = useState({})
  const triggerUpdate = () => setUpdate({})

  if (!magic.loadStarted) {
    magic.loadStarted = true
    setTimeout(() => {
      magic.loadFinished = true
      triggerUpdate()
    }, 5000)
  }

  const { component: Component, path, mode } = props

  const checkCashUser = () => {
    if (!currentUser) return false
    if (mode === 'userOnly') return true
    if (currentUser.isAdmin) return true
    const notCashTypes = currentUser.station.filter(oneStation => oneStation.contract?.contractType === 'Barter' || oneStation.contract?.contractType === 'Barter Settlement')
    if (notCashTypes.length === 0) return false
    else return true
  }

  const waitingForUser = !currentUser && !magic.loadFinished

  return (
    <Route path={path} exact render={props => (userLoading || sessionLoading || waitingForUser) ? <LoadingScreen /> : (checkCashUser() ? <Component {...props} /> : <Redirect to='/' />)} />
  )
}

export default ProtectedRoute
