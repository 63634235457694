import React, { useEffect, useState } from 'react'

const TrackLoading = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const renderOnSize = () => setWindowWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', renderOnSize)

    return () => window.removeEventListener('resize', renderOnSize)
  }, []) // eslint-disable-line

  if (windowWidth <= 768) {
    return (
      <div className='explore__track-wrapper explore__track-wrapper--mobile'>
        <div className='explore__track-art-title explore__track-art-title--mobile'>
          <div>
            <div className='explore__mobile-album-art-wrapper'>
              {/* <img src={albumArt} alt='' /> */}
            </div>
            <div className='explore__mobile-titles-wrapper'>
              <span>{/* song name */} Loading... </span><br />
              <span>{/* album name */} Loading... </span><br />
              <span className='is-italic'>{/* library name */} Loading... </span>
            </div>
          </div>
        </div>
        <div className='mobile explore__mobile-duration-wrapper'>
          <span>:00</span>
        </div>
        <div className='explore__track-controls explore__track-controls--mobile'>
          {/* {userBookmarks && <button type='button' onClick={isActiveUser ? bookmarkTrack : askToLogin}><FontAwesomeIcon icon={isBookmarked ? faStar : ['fal', 'star']} /></button>}
          {addToList && <button type='button' onClick={isActiveUser ? addToList : askToLogin}><FontAwesomeIcon icon={faPlus} /></button>}
          {remove && <button type='button' onClick={isActiveUser ? removeFromPlaylist : askToLogin}><FontAwesomeIcon icon={['fal', 'trash-alt']} /></button>}
          {copy && <button type='button' onClick={isActiveUser ? copy : askToLogin}><FontAwesomeIcon icon={['fal', 'copy']} /></button>} */}
        </div>
      </div>
    )
  }

  return (
    <div className='explore__track-wrapper columns is-vcentered mb-5'>
      <div className='column is-4 explore__track-art-title'>
        <div className='explore__track-art-title--inner-wrapper'>
          {/* <img src={albumArt} alt='' /> */}
        </div>
        &nbsp;
        <span>
          Loading...
        </span>
      </div>
      <div className='column is-5 explore__track-album'>
        <div className='columns is-multiline'>
          <div className='column is-full is-paddingless'>
            <span>Loading...</span>
          </div>
          <div className='column is-11 is-paddingless'>
            <span className='is-italic'>Loading...</span>
          </div>
          <div className='column is-1 is-paddingless'>
            <span>:00</span>
          </div>
        </div>
      </div>
      <div className='column is-3 explore__track-controls'>
        {/* {userBookmarks && <button type='button' onClick={isActiveUser ? bookmarkTrack : askToLogin}><FontAwesomeIcon icon={isBookmarked ? faStar : ['fal', 'star']} /></button>}
        <button type='button' onClick={isActiveUser ? downloadDisclaimer : askToLogin}><FontAwesomeIcon icon={['fal', 'cloud-download']} /></button>
        {addToList && <button type='button' onClick={isActiveUser ? addToList : askToLogin}><FontAwesomeIcon icon={faPlus} /></button>}
        {remove && <button type='button' onClick={isActiveUser ? removeFromPlaylist : askToLogin}><FontAwesomeIcon icon={['fal', 'trash-alt']} /></button>}
        {copy && <button type='button' onClick={isActiveUser ? copy : askToLogin}><FontAwesomeIcon icon={['fal', 'copy']} /></button>} */}
      </div>
    </div>
  )
}

export default TrackLoading
