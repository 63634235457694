import { faCaretLeft, faCheckCircle, faEdit } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import React from 'react'

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const eleven = suffix => new Array(11).fill().map((v, i) => `${i + 1}${suffix}`)
const hours = ['12AM', ...eleven('AM'), '12PM', ...eleven('PM')]

const AffidavitReview = ({ name, monday, actualDate, playbacks, week, submittedBy, submittedAt, readOnly, backAction, backText, addAirTimesAction }) => {
  const dispatch = useDispatch()

  playbacks.sort((a, b) => (
    days.indexOf(a.day) - days.indexOf(b.day) ||
    hours.indexOf(`${a.hour}${a.suffix}`) - hours.indexOf(`${b.hour}${b.suffix}`) ||
    (a.minute || 0) - (b.minute || 0)
  ))

  const visibleWeek = new Date(actualDate.replace(/-/g, '/') + ' 0:00').toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })

  return (
    <div className='affidavit-review'>
      <div className='level mb-0'>
        <div className='level-left'>
          <div>
            <h1 className='title'>Certficate of Performance</h1>
            <h2 className='subtitle'>For {name} on week of {visibleWeek}</h2>
          </div>
        </div>
        <div className='level-right'>
          {
            !readOnly
              ? (
                <>
                  <button className='button is-primary mr-3' onClick={() => dispatch({ type: addAirTimesAction })}>
                    <span className='icon'>
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                    <span>
                      Add Air Times
                    </span>
                  </button>
                  <button className='button is-primary' onClick={() => dispatch({ type: 'affidavits.enterSubmitting' })}>
                    <span className='icon'>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    <span>
                      Submit
                    </span>
                  </button>
                </>
                )
              : undefined
          }
        </div>
      </div>
      <div className='my-4 content'>
        <p>
          <strong>IMPORTANT:</strong>
          {' '}
          This Certficate of Performance is an affidavit and acts as a legal satement by your company that affirms all national
          sponsor commercials aired, unedited, during the dates and times as indicated. Please ensure that the information
          submitted through this form is true and accurate.
        </p>
        {
          submittedBy
            ? (
              <p>
                Certificate of Performance submitted by {submittedBy?.firstName} {submittedBy?.lastName} ({submittedBy?.email}) on {
                  new Date(submittedAt).toLocaleString('en-us', { month: 'long', day: 'numeric', year: 'numeric' })
                }
              </p>
              )
            : undefined
        }
      </div>
      <h2 className='subtitle affidavit-review__back-button my-4'>
        <a className='affidavit-review__back-link' onClick={() => dispatch({ type: backAction })}>
          <span className='icon'>
            <FontAwesomeIcon icon={faCaretLeft} />
          </span>
          <span>
            {backText}
          </span>
        </a>
      </h2>
      <h2 className='subtitle has-text-link mb-2'>
        Calendar View
      </h2>
      <div className='affidavit-review__doc'>
        <div className='affidavit-review__isci'>
          <div className='affidavit-review__columns'>
            {
              days.map(day => {
                return (
                  <div className='affidavit-review__day' key={day}>
                    <div className='affidavit-review__column-title'>{day}</div>
                    <div className='affidavit-review__column-list'>
                      {
                        playbacks
                          .filter(playback => playback.day === day)
                          .map(playback => {
                            const commercial = (week?.commercials?.map(comm => comm.doc).filter(commercial => commercial?.isci === playback.isci) || [])[0]

                            return (
                              <div className='affidavit-review__playback' key={playback.id}>
                                <div className='affidavit-review__playback-isci'>
                                  {playback.isci}
                                </div>
                                <div className='affidavit-review__playback-name'>
                                  <strong>Name:</strong> <span>{commercial?.name}</span>
                                </div>
                                <div className='affidavit-review__playback-duration'>
                                  <strong>Duration:</strong> 0{commercial?.duration}
                                </div>
                                <div className='affidavit-review__playback-time'>
                                  {playback.time === 'As Ordered' ? 'Spot Aired As Ordered' : `Spot Aired At ${playback.time}`}
                                </div>
                              </div>
                            )
                          })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AffidavitReview
