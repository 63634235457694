import React from 'react'
import MainLayout from '../containers/MainLayout'
import { Helmet } from 'react-helmet'

const TermsOfService = props => {
  return (
    <MainLayout fullheight>
      <Helmet>
        <title>Terms of Service - TAA Music</title>
      </Helmet>
      <div className='container'>
        <div className='columns'>
          <div className='terms-page column is-full content'>
            <h1>Terms of Service</h1>
            <h3>Introduction</h3>
            <p>TAA Music, Inc (herein referred to as "TAA") is dedicated to providing production music and digital services to the media industry. We provide this website, portal and cloud-based tools in the delivery of our services to customers and subscribers. These Terms of Service provide rules and guidelines by which users may utilize our digital services.</p>
            <h3>Consent</h3>
            <p><strong>By visiting, utilizing and conducting business on this website you agree to be bound by these Terms of Service as stated herein. Your use of this website also constitutes your acceptance and agreement to our Privacy Policy.</strong></p>
            <h3>Governing Law</h3>
            <p>The laws of the State of Texas shall apply to any dispute arising from or relating to these Terms or Service. The venue for any such dispute will be Denton County, Texas.</p>
            <h3>Right to Make Changes to Terms</h3>
            <p>TAA reserves the right to change or update these Terms of Service at it's discretion and at any time. The latest copy of this agreement can always be found at <a href='https://taamusic.com'>https://www.taamusic.com</a>.</p>
            <h3>User Guidelines</h3>
            <ul>
              <li>You agree that TAA is the exclusive owner of all video and audio media provided by this service.</li>
              <li>You agree that TAA provides this website and digital services to it's subscribers and account holders for the purpose of facilitating legal and contractual obligations to provide access to a royalty-free, production music library.</li>
              <li>If you are an account holder, you agree that by accessing and using this website you are an actively employed representative of the company under which you were granted an account.</li>
              <li>If downloading music, sounds or effects from this website, you agree that TAA grants you a non-exclusive, non-transferable license to utilize such media for the purposes of audio and video media production. The licensee is solely responsible for maintaining any necessary public performance license with ASCAP.</li>
              <li>You agree that by searching and downloading music you are solely engaging in a procurement for audio assets for creative brainstorming, browsing and for incorporation into a specific audio or media project by the station licensed through our Licensee agreement on file at TAA.</li>
              <li>You agree to <strong>not</strong> engage in any mass downloads for purposes of stockpiling, archiving or otherwise duplicating TAA's music catalog.</li>
              <li>You agree to not reverse-engineer, disassemble, modify or otherwise tamper with the source code of any TAA digital property.</li>
              <li>If you are an account holder and subject to legal compliance, you agree that submitting commercial air time logs through this website constitutes a legal statement by your organization and affirm that the data submitted is true and accurate.</li>
              <li><strong>TAA reserves the right to revoke account privileges at any time, from any account holder for violation of above stated User Guidelines.</strong></li>
            </ul>
            <h3>Copyright and Intellectual Property</h3>
            <p>All content on this website, including but not limited to text, software, scripts, graphics, photos, sounds, music, videos, interactive features, trademarks, service marks and logos contained herein are owned by TAA, unless otherwise noted, and are subject to copyright and other intellectual property rights under the laws of The United States of America.</p>
            <h3>Warranty Disclaimer</h3>
            <p>You agree that your use of the TAA website and digital services shall be at your sole risk. You agree, to the extent permitted by law, that TAA, it's officers, directors, managers, employees or agents disclaim all warranties expressed or implied in connection with your use of this website.  TAA makes no warranties or representations about the accuracy or completeness of this site's content or functionality. In no event will TAA be liable for any special, incidental, indirect or consequential damages whatsoever arising from your access or use of, or inability to access or use, this site. If you consent to use this website, you agree to use the website as is.</p>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default TermsOfService
