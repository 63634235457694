import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'

import MainLayout from '../containers/MainLayout'
import BackToTopSideButton from '../components/BackToTopSideButton'
import Metric from '../components/Home/Metric'
import Feature from '../components/Home/Feature'
import Gradients from '../components/Home/Gradients'
import MainSVG from '../components/Home/MainSVG'
import createAction from '../action'

const Home = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [searchUpdateTimeout, setSearchUpdateTimeout] = useState(0)
  const [showNeedHelp, setNeedHelp] = useState(false)

  const handleOnChange = ev => {
    if (searchUpdateTimeout) {
      clearTimeout(searchUpdateTimeout)
    }

    setSearchUpdateTimeout(
      setTimeout(() => {
        dispatch(createAction('playerNFilters.songSearch', { songSearch: ev.target.value }))
        history.push('/explore')
      }, 2000)
    )
  }

  const handleSlideScroll = kind => {
    window.scrollBy({ top: window.innerHeight, behavior: 'smooth' })
    if (kind === 'bottomScroll') {
      window.gtag('event', 'select_item', {
        items: [{
          item_id: '1',
          item_name: 'Scroll_Click'
        }],
        item_list_name: 'Home_Scroll',
        item_list_id: '1'
      })
    } else {
      window.gtag('event', 'select_item', {
        items: [{
          item_id: '3',
          item_name: 'ServicesWeOffer'
        }],
        item_list_name: 'Home_NeedHelp',
        item_list_id: '1'
      })
    }
  }

  const handleNeedHelp = () => {
    setNeedHelp(true)
    window.gtag('event', 'select_item', {
      items: [{
        item_id: '0',
        item_name: 'Need_Help'
      }],
      item_list_name: 'Home_NeedHelp',
      item_list_id: '1'
    })
  }

  const sendGA = which => {
    if (which === 'contactUs') {
      window.gtag('event', 'select_item', {
        items: [{
          item_id: '2',
          item_name: 'ContactUs'
        }],
        item_list_name: 'Home_NeedHelp',
        item_list_id: '1'
      })
    } else {
      window.gtag('event', 'select_item', {
        items: [{
          item_id: '1',
          item_name: 'CallUs'
        }],
        item_list_name: 'Home_NeedHelp',
        item_list_id: '1'
      })
    }
  }

  return (
    <MainLayout fullheight alignstart innerClasses={['home-body', 'is-flex-direction-column']}>
      <Helmet>
        <title>TAA Music - Royalty Free Stock Music</title>
      </Helmet>
      <Gradients />
      <div className='home-content__upper-part'>
        <div className='container home-content'>
          <div className='first-slide'>
            <div className='columns is-centered is-multiline'>
              <div className='column'>
                <h1 className='is-size-3-desktop has-text-purple has-text-centered is-family-secondary is-size-4-tablet is-size-5-mobile'>Over 1 million cuts of music for production</h1>
                <p className='has-text-centered home-main-message gradient-text'>Easier to search</p>
              </div>
              <div className='column is-four-fifths'>
                <input type='text' className='home-search is-size-1 px-6 py-2' placeholder='type your search' onChange={handleOnChange} />
                <div className={classNames('columns need-help-section is-centered has-text-centered m-1 is-clipped has-text-purple is-desktop', { 'show-help': showNeedHelp })}>
                  <div onClick={handleNeedHelp} className='column is-full need-help__trigger'>Need Help?</div>
                  <div className='column is-one-third-desktop need-help__el is-hidden-mobile'>
                    <Link to='/contact' onClick={() => sendGA('contactUs')}>Questions Answered Quickly</Link>
                  </div>
                  <div className='column is-one-third-desktop need-help__el'>
                    <Link to='/contact' onClick={() => sendGA('contactUs')}>Contact Us</Link>
                  </div>
                  <div className='column is-one-third-desktop need-help__el is-hidden-mobile'>
                    <a onClick={() => handleSlideScroll('needHelpScroll')}>Services We Offer</a> {/* eslint-disable-line */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='main-svg-wrapper'>
          <MainSVG />
        </div>
        <button type='button' onClick={() => handleSlideScroll('bottomScroll')} className='scroll-next-page is-uppercase is-family-secondary is-slightly-bigger px-4'>Scroll</button>
      </div>
      <div className='container'>
        <div className='second-slide'>
          <BackToTopSideButton watchTargetSelector='.second-slide' />
          <div className='columns'>
            <div className='column is-two-fifths is-flex is-flex-direction-column is-justify-content-center'>
              <Metric number='1,000,000' category='Cuts of music' />
              <Metric number='142' category='Genres' delay={400} />
              <Metric number='110' category='Instruments' delay={800} />
            </div>
            <div className='column is-6 is-offset-1 is-flex is-flex-direction-column is-justify-content-center'>
              <Feature
                icon={['fal', 'sliders-v']}
                title='Complimentary Music Production'
                content={
                  <>
                    Tell us your tempo, flair, genre, instrumentation or concept.<br />
                    Work with our experts to produce a track based on your suggestions.<sup>*</sup>
                  </>
                }
                ctaText='Inquire Now'
              />
              <Feature
                icon={['fal', 'search']}
                iconProps={{ rotation: 90 }}
                title='Complimentary Music Search Assistance'
                content={
                  <>
                    Tap the knowledge of our experts in finding the perfect track from our vast libraries. We are here to help.
                  </>
                }
                ctaText='Request Assistance'
              />
              <Feature
                icon={['fal', 'waveform']}
                title='Flexible Subscriptions'
                content={
                  <>
                    Becoming a subscriber with TAA Music is easy. Choose from monthly,  yearly or barter-based licensing. Your station or company will enjoy all the benefits of working with TAA Music.
                  </>
                }
                ctaText='Inquire Now'
              />
              <Feature
                icon={['fal', 'hand-peace']}
                title='Subscribers Come First'
                content={
                  <>
                    With fast search, dark-mode, easy music player and improved playlists, our website is a place where subscribers can be productive.
                  </>
                }
              />
              <Feature
                icon={['fal', 'comments']}
                title='You Asked, We Listened'
                content={
                  <>
                    We’ve streamlined our affiliate portal for easier traffic downloads, efficient compliance submission and clear instructions.<br /><br />
                    <small><sup>*</sup> Some restrictions apply. Offer is available to TAA Music subscribers and partners.</small>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Home
