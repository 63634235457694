import { faSyncAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { useAction, useDocument, useSession } from '../frankentangel'
import AffidavitForm from './AffidavitForm'
import AffidavitReview from './AffidavitReview'
import AffidavitSubmitScreen from './AffidavitSubmitScreen'
import AffidavitThankYouScreen from './AffidavitThankYouScreen'

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const AffidavitsSubmissionSystem = (props) => {
  const selectedStation = useSelector(state => state.affidavits.station)
  const selectedWeek = useSelector(state => state.affidavits.week)
  const selectedMethod = useSelector(state => state.affidavits.method)
  const isAddingAirtimes = useSelector(state => state.affidavits.isAddingAirtimes)
  const isReviewing = useSelector(state => state.affidavits.isReviewing)
  const isSubmitting = useSelector(state => state.affidavits.isSubmitting)
  const isSubmittingSuccessful = useSelector(state => state.affidavits.isSubmittingSuccessful)

  const [session, , sessionLoading] = useSession()
  const [user, , userLoading] = useDocument('users', session?.user)
  const [station, , stationLoading] = useDocument('accounts', selectedStation)
  const [week, weekError, weekLoading] = useAction('commercial-weeks', 'getCommercials', { id: selectedWeek, accountId: selectedStation })
  const [affidavits, affidavitsError, affidavitsLoading] = useAction('affidavits', 'read', { week: selectedWeek, account: selectedStation })

  if (weekError) console.error('error with week to report', weekError)
  if (affidavitsError) console.error('error with affidavits to report', weekError)

  const isLoading = weekLoading || affidavitsLoading || stationLoading || sessionLoading || userLoading

  const isciCodes = week?.commercials
    .map(commercial => {
      const runCounts = [0, 0, 0, 0, 0, 0, 0]
      commercial.doc.days.forEach(day => (runCounts[days.indexOf(day.day)] += 1))

      return {
        isci: commercial.doc.isci,
        runCounts
      }
    }).reduce((acc, { isci, runCounts }) => {
      return {
        ...acc,
        [isci]: { runCounts }
      }
    }, {}) || {}

  const automaticPlaybacks = Object.keys(isciCodes)
    .map(isci => (
      isciCodes[isci].runCounts
        .map((number, index) => new Array(number).fill().map((value, runIndex) => ({ day: days[index], time: 'As Ordered', isci, id: `${isci}-${index}-${runIndex}` })))
        .flat()
    ))
    .flat()

  const manualPlaybacks = useSelector(state => state.affidavits.playbacks).filter(playback => playback.day)
  const playbacks = [...automaticPlaybacks, ...manualPlaybacks]

  const recordedPlaybacks = (affidavits?.affidavits || [])
    .map(affidavit => affidavit?.doc || [])
    .map(affidavit => (affidavit?.playbacks?.list || []).map(playback => ({ ...playback, isci: affidavit.isci })))
    .flat()

  const submittedBy = (affidavits?.affidavits || [])[0]?.doc?.submittedBy
  const submittedAt = (affidavits?.affidavits || [])[0]?.doc?.submittedAt

  return (
    <div className='affidavits-submission-system'>
      {
        isLoading
          ? (
            <p className='affidavits-system__loading has-text-centered'>
              <FontAwesomeIcon icon={faSyncAlt} spin size='6x' />
            </p>
            )
          : (
              props.readOnly
                ? (
                  <AffidavitReview
                    name={station.callsign}
                    actualDate={week.actualDate}
                    playbacks={recordedPlaybacks}
                    week={week}
                    submittedBy={submittedBy}
                    submittedAt={submittedAt}
                    readOnly
                    backText='Back to Week Selection'
                    backAction='affidavits.clearWeek'
                  />
                  )
                : (
                    !isSubmitting
                      ? (
                          selectedMethod === 'automatic'
                            ? (
                                !isAddingAirtimes
                                  ? (
                                    <AffidavitReview
                                      name={station.callsign}
                                      actualDate={week.actualDate}
                                      playbacks={playbacks}
                                      week={week}
                                      backText='Back to Reporting Method'
                                      backAction='affidavits.clearMethod'
                                      addAirTimesAction='affidavits.enterAddingAirtimes'
                                    />
                                    )
                                  : (
                                    <AffidavitForm
                                      week={week}
                                      account={station}
                                      isciCodes={isciCodes}
                                      receivedPlaybacks={automaticPlaybacks}
                                      backText='Back to Affidavit Review'
                                      backAction='affidavits.exitAddingAirtimes'
                                      reviewAction='affidavits.exitAddingAirtimes'
                                    />
                                    )
                              )
                            : (
                                !isReviewing
                                  ? (
                                    <AffidavitForm
                                      week={week}
                                      account={station}
                                      isciCodes={isciCodes}
                                      receivedPlaybacks={[]}
                                      backText='Back to Reporting Method'
                                      backAction='affidavits.clearMethod'
                                      reviewAction='affidavits.enterReviewing'
                                    />
                                    )
                                  : (
                                    <AffidavitReview
                                      name={station.callsign}
                                      actualDate={week.actualDate}
                                      playbacks={manualPlaybacks}
                                      week={week}
                                      backText='Back to Air Time Entry'
                                      backAction='affidavits.exitReviewing'
                                      addAirTimesAction='affidavits.exitReviewing'
                                    />
                                    )
                              )
                        )
                      : (
                          !isSubmittingSuccessful
                            ? (
                              <AffidavitSubmitScreen
                                user={user}
                                account={station}
                                week={week}
                                playbacks={selectedMethod === 'automatic' ? playbacks : manualPlaybacks}
                              />
                              )
                            : (
                              <AffidavitThankYouScreen
                                user={user}
                                account={station}
                                week={week}
                                playbacks={selectedMethod === 'automatic' ? playbacks : manualPlaybacks}
                              />
                              )
                        )
                  )
            )
      }
    </div>
  )
}

export default AffidavitsSubmissionSystem
