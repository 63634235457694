import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

export const Tabs = props => {
  const { headers, mode, children } = props

  const [activeTab, setActiveTab] = useState(0)

  const switchTabWithKeys = ev => {
    if (ev.key === 'ArrowRight' && (ev.ctrlKey || ev.metaKey)) setActiveTab(prevTab => prevTab + 1)
    if (ev.key === 'ArrowLeft' && (ev.ctrlKey || ev.metaKey)) setActiveTab(prevTab => prevTab > 0 ? prevTab - 1 : 0)
  }

  useEffect(() => {
    window.addEventListener('keyup', ev => switchTabWithKeys(ev))
    return () => window.removeEventListener('keyup', ev => switchTabWithKeys(ev))
  }, []) // eslint-disable-line

  const headersMarkup = headers.map((header, index) => {
    return (
      <li className={classNames({ 'is-active': activeTab === index })} onClick={() => setActiveTab(index)} key={index}>
        <a> {/* eslint-disable-line */}
          <span>{header}</span>
        </a>
      </li>
    )
  })

  return (
    <>
      <div className={classNames('tabs', mode)}>
        <ul>
          {headersMarkup}
        </ul>
      </div>
      {React.Children.map(children, (child, index) => React.cloneElement(child, { current: activeTab, order: index }))}
    </>
  )
}

export const Tab = props => {
  const { order, children, current } = props

  const isHidden = current !== order
  if (isHidden) return <></>

  return (
    <div className={classNames({ 'is-hidden': isHidden })}>
      {children}
    </div>
  )
}
