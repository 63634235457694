const initialState = {
  songSearch: '',
  songUrl: '',
  songTitle: ' ',
  songGenres: [],
  songTempo: '',
  albumArt: '',
  trackIndex: null
}

const playerNFiltersReducer = (state = initialState, action = {}) => {
  const type = action.type

  if (type === 'playerNFilters.playSong') return ({ ...state, songUrl: action.songUrl, songTitle: action.songTitle, albumArt: action.albumArt, songGenres: action.songGenres, songTempo: action.songTempo })
  if (type === 'playerNFilters.playSongReset') return ({ ...state, songUrl: initialState.songUrl, songTitle: initialState.songTitle, albumArt: initialState.albumArt, songGenres: initialState.songGenres, songTempo: initialState.songTempo })
  if (type === 'playerNFilters.songSearch') return ({ ...state, songSearch: action.songSearch })
  if (type === 'playerNFilters.songFilters') return ({ ...state, songGenres: action.songGenres, songTempo: action.songTempo })
  if (type === 'playerNFilters.trackIndex') return ({ ...state, trackIndex: action.trackIndex })
  if (type === 'playerNFilters.resetTrackIndex') return ({ ...state, trackIndex: null })
  if (type === 'playerNFilters.playNext') return ({ ...state, trackIndex: state.trackIndex === null ? 0 : (state.trackIndex + 1) })
  if (type === 'playerNFilters.playPrevious') return ({ ...state, trackIndex: state.trackIndex === 0 ? 0 : (state.trackIndex - 1) })

  return state
}

export default playerNFiltersReducer
