import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import zxcvbn from 'zxcvbn'

import useForm from './useForm'

const FormUsePasswordInput = props => {
  const { passwordState, setPasswordState } = props

  const [errorMsg, setErrorMsg] = useState('')

  const [fieldValue, fieldState, handleFieldChange, handleFieldBlur] = useForm('password') // eslint-disable-line

  useEffect(() => {
    if (fieldState.state === 'invalid' && errorMsg === '') validateExtra()
    if (passwordState.matching && errorMsg !== '') validateExtra()
  }, [fieldState.state, passwordState.matching]) // eslint-disable-line

  const validateExtra = () => {
    const { ready, password, strongEnough } = getPassword()
    if (!ready && password) {
      if (!strongEnough) setErrorMsg('Weak password')
      else setErrorMsg('Passwords not matching')
    } else if (fieldState.state === 'invalid') setErrorMsg(fieldState.errorMsg)
    else setErrorMsg('')
  }

  const handleOnBlur = ev => {
    handleFieldBlur('password', ev.target.value)
    validateExtra()
  }
  const editPassword = (event) => {
    handleFieldChange('password', event.target.value)
    validateExtra()

    const password = event.target.value
    const strength = Math.min(zxcvbn(password, ['taamusic']).guesses_log10, 20)
    const matching = password === passwordState.confirmPassword

    setPasswordState({ ...passwordState, password, strength, matching })
  }
  const editConfirmPassword = (event) => {
    const confirmPassword = event.target.value
    const matching = passwordState.password === confirmPassword

    setPasswordState({ ...passwordState, confirmPassword, matching })
  }

  const getPassword = () => ({
    password: passwordState.password,
    ready: passwordState.matching && passwordState.strength >= 10,
    matching: passwordState.matching,
    strength: passwordState.strength,
    strongEnough: passwordState.strength >= 10
  })

  const passwordInput = (
    <>
      <div className='field'>
        <div className='label'>Password</div>
        <div className='control'>
          <input className={classNames('input', { 'is-danger': errorMsg !== '' })} onBlur={handleOnBlur} type='password' placeholder='Password' name='password' value={passwordState.password} onChange={editPassword} />
          {errorMsg !== '' && (<p className='help is-danger'>{errorMsg}</p>)}
        </div>
      </div>
      <div className='field'>
        <div className='label'>Password strength</div>
        <div className='control'>
          <progress
            className={classNames('progress', {
              'is-danger': passwordState.strength > 0 && passwordState.strength < 10,
              'is-warning': passwordState.strength >= 10 && passwordState.strength < 15,
              'is-success': passwordState.strength >= 15
            })} value={passwordState.strength} max={20}
          >{(passwordState.strength / 20).toFixed(0)}%
          </progress>
        </div>
        <p className='help is-danger'>
          {passwordState.strength > 0 && passwordState.strength < 10 ? 'The password is not strong enough' : '\u00a0'}
        </p>
      </div>
      <div className='field'>
        <div className='label'>Confirm password</div>
        <div className='control'>
          <input
            className={classNames('input', {
              'is-danger': !passwordState.matching || fieldState.state === 'invalid'
            })} type='password' placeholder='Confirm password' value={passwordState.confirmPassword} onChange={editConfirmPassword}
          />
        </div>
        <p className='help is-danger'>
          {!passwordState.matching || fieldState.state === 'invalid' ? "The passwords don't match or missing" : '\u00a0'}
        </p>
      </div>
    </>
  )

  return passwordInput
}

export default FormUsePasswordInput
