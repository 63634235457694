const initialState = {
  station: 0,
  week: 0
}

const commercialsReducer = (state = initialState, action = {}) => {
  const type = action.type

  if (type === 'commercials.setStation') return { ...state, station: action.station }
  if (type === 'commercials.clearStation') return { ...state, station: 0 }

  if (type === 'commercials.setWeek') return { ...state, week: action.week }
  if (type === 'commercials.clearWeek') return { ...state, week: 0 }

  return state
}

export default commercialsReducer
