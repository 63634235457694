import React, { useState, useEffect } from 'react'
import unwrap from 'async-unwrap'
import useModal from './useModal'
import FormContext from './FormContext'
import * as immutable from 'object-path-immutable'
import classNames from 'classnames'

const FormWrapper = props => {
  const prepareInitialContents = content => {
    const baseObject = props.fields.describe().fields
    const fields = Object.keys(baseObject)

    function createContent (value) {
      if (baseObject[value].type === 'object') {
        return { ...Object.keys(baseObject[value].fields).reduce((acc, name) => ({ ...acc, [name]: content }), {}) }
      } else {
        return content
      }
    }
    return { ...fields.reduce((acc, name) => ({ ...acc, [name]: createContent(name) }), {}) }
  }

  const [values, manageValues] = useState(prepareInitialContents(''))
  const [states, manageStates] = useState(prepareInitialContents({ state: 'silent', errorMsg: '' }))
  const [initialsSet, areInitialsSet] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [showErrorsList, toggleErrorsList] = useState(false)
  const [status, setStatus] = useState({
    initial: true,
    success: '',
    error: ''
  })
  const [isFormStatus, setIfFormStatus] = useState(false)
  const [modal, showModal] = useModal()

  const validateField = (fieldName, fieldValue) => {
    const valuesToValidate = immutable.set({ ...values }, fieldName, fieldValue)
    props.fields.validate(valuesToValidate, { abortEarly: false })
      .then(value => {
        setFieldState(fieldName, 'valid')
      })
      .catch(err => {
        const fieldError = err.inner.filter(error => error.path === fieldName)
        if (fieldError.length > 0) setFieldState(fieldName, 'invalid', fieldError[0].errors[0])
        else setFieldState(fieldName, 'valid')
      })
  }

  const setFieldState = (fieldName, state, errorMsg = '') => {
    manageStates(states => immutable.set(states, fieldName, { state, errorMsg }))
  }

  const updateFieldValue = (fieldName, fieldValue) => {
    manageValues(values => immutable.set(values, fieldName, fieldValue))
  }

  const handleFieldChange = (fieldName, fieldValue) => {
    updateFieldValue(fieldName, fieldValue)
    if (immutable.get(states, fieldName).state === 'silent') return
    validateField(fieldName, fieldValue)
  }

  const handleFieldBlur = (fieldName, fieldValue) => {
    updateFieldValue(fieldName, fieldValue)
    validateField(fieldName, fieldValue)
  }

  const prepareEditableContent = (base, guest) => {
    const baseObject = { ...base }
    const guestObject = { ...guest }

    function handleValueObject (value) {
      if (typeof baseObject[value] === 'object') {
        if (!guestObject[value] || guestObject[value] === undefined) return baseObject[value]
        return { ...Object.keys(baseObject[value]).reduce((acc, name) => ({ ...acc, [name]: guestObject[value][name] === undefined ? '' : guestObject[value][name] }), {}) }
      } else {
        return guestObject[value]
      }
    }
    return { ...Object.keys(baseObject).reduce((acc, name) => ({ ...acc, [name]: handleValueObject(name) }), {}) }
  }

  useEffect(() => {
    if (typeof props.initialValuesFrom !== 'undefined' && Object.keys(props.initialValuesFrom).length > 0 && !initialsSet) {
      manageValues(prepareEditableContent(values, props.initialValuesFrom))
      areInitialsSet(true)
    }
  }, [props.initialValuesFrom]) // eslint-disable-line

  const resetForm = () => {
    manageValues(prepareInitialContents(''))
    manageStates(prepareInitialContents({ state: 'silent', errorMsg: '' }))
    areInitialsSet(false)
  }

  const setIsFormStatus = formStatusPresent => {
    setIfFormStatus(formStatusPresent)
  }

  const handleStatus = currentStatus => {
    setStatus({ ...currentStatus })
    setTimeout(() => { setStatus({ ...currentStatus, initial: true }) }, 5000)
    // setTimeout(() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' }), 400)
  }

  const handleKeyUp = ev => {
    if ((ev.key === 'Enter') && (ev.target.tagName.toLowerCase() !== 'textarea')) onSubmit(ev)
    if (ev.metaKey && ev.key === 'Enter') onSubmit(ev)
    if (ev.ctrlKey && ev.key === 'Enter') onSubmit(ev)
  }

  const onSubmit = async ev => {
    ev.preventDefault()

    const [validationError, validationResult] = await props.fields.validate(values, { abortEarly: false })[unwrap]
    if (validationResult) setSubmitting(true)

    if (validationError) {
      const errorStates = states
      validationError.inner.forEach(error => { errorStates[error.path] = { state: 'invalid', errorMsg: error.errors[0] } })
      toggleErrorsList(true)
      return false
    }

    const [error, result] = await props.submit(values)[unwrap]

    setSubmitting(false)

    let currentStatus

    if (error) {
      const errorMsg = error ? (error.message || error) : 'Unknown Error'
      currentStatus = { initial: false, error: errorMsg }
      if (!isFormStatus) await showModal('Error', errorMsg, [{ text: 'OK', className: 'is-danger', response: 'OK' }])
    }

    if (result) {
      const successMsg = typeof result === 'string' ? result : 'Successful Submission'
      currentStatus = { initial: false, success: successMsg }
      resetForm()
    }

    handleStatus(currentStatus)
  }

  return (
    <FormContext.Provider value={{ fields: props.fields, initialValuesFrom: props.initialValuesFrom, isSubmitting, onSubmit: ev => onSubmit(ev), showErrorsList, values, states, status, isFormStatus, setIsFormStatus, handleFieldChange, handleFieldBlur }}>
      <form className={classNames(props.className, props.classes)} onSubmit={ev => onSubmit(ev)} onKeyUp={ev => handleKeyUp(ev)}>
        {props.children}
      </form>
      {modal}
    </FormContext.Provider>
  )
}

export default FormWrapper
