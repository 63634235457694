import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import createAction from '../../../action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Track from '../Track'
import { useSearchableList } from '../../../frankentangel'
import { faSync, faTimes } from '@fortawesome/pro-light-svg-icons'
import AddToPlaylist from '../AddToPlaylist'

const Search = props => {
  const { currentUser, userBookmarks, userContract } = props
  const [searchText, setSearchText] = useState('')
  const state = useSelector(state => ({ songSearch: state.playerNFilters.songSearch }))
  const [searchUpdateTimeout, setSearchUpdateTimeout] = useState(0)
  const [showAddToPlaylist, setAddToPlaylist] = useState({ show: false, trackId: null })
  const dispatch = useDispatch()

  useEffect(() => {
    setSearchText(state.songSearch)
    dispatch(createAction('playerNFilters.resetTrackIndex'))
  }, [state.songSearch]) // eslint-disable-line

  const handleOnChange = ev => {
    setSearchText(ev.target.value)

    if (searchUpdateTimeout) {
      clearTimeout(searchUpdateTimeout)
    }

    setSearchUpdateTimeout(
      setTimeout(() => {
        dispatch(createAction('playerNFilters.songSearch', { songSearch: ev.target.value }))
        dispatch(createAction('playerNFilters.resetTrackIndex'))
      }, 2000)
    )
  }

  const clearTheFilters = () => {
    dispatch(createAction('playerNFilters.songSearch', { songSearch: '' }))
  }

  const [startingCursor] = useState(Math.floor(Math.random() * 40000))
  const [Paginator, result,, loading] = useSearchableList('song', searchText, 'list', {}, 'explore', startingCursor)

  const songs = result
    ? result.map((song, index) => {
        return (
          <Track
            key={song.doc.id}
            trackId={song.doc.id}
            trackIndex={index}
            title={song.doc.mix ? `${song.doc.song_title} (${song.doc.mix})` : song.doc.song_title}
            albumArt={`https://cdn.taamusic.com/album-artwork/${song.doc.album?.album_name}.png`}
            albumName={song.doc.album?.album_name}
            libraryName={song.doc.album?.library?.library_name}
            duration={song.doc.song_length}
            genres={song.doc.genre.map(gen => ({ title: gen.genre.replace('/', ' / '), searchTerm: gen.technical_name }))}
            tempo={song.doc.tempo}
            isActiveUser={!!currentUser}
            userContract={userContract}
            userBookmarks={userBookmarks}
            addToList={() => setAddToPlaylist({ show: true, trackId: song.doc.id, playlistId: null })}
          />
        )
      })
    : null

  return (
    <div className='explore__search'>
      <div className='explore__search-input-wrapper'>
        <input type='text' value={searchText} className='explore__search-input' onChange={handleOnChange} placeholder='type your search' />
        <button type='button' onClick={clearTheFilters} className='is-family-secondary is-uppercase clear-filters'>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      {(!loading && (songs.length === 0 || songs === null)) && <p className='has-text-centered'>No results found for given search phrase</p>}
      <div className='explore__search-results-wrapper'>
        <Paginator spinner={<div className='loadingSpinner'><FontAwesomeIcon icon={faSync} spin size='3x' /></div>}>
          {songs}
        </Paginator>
      </div>
      {currentUser && <AddToPlaylist active={showAddToPlaylist} setActive={setAddToPlaylist} mode='add' />}
    </div>
  )
}

export default Search
