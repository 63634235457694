import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import MainLayout from '../containers/MainLayout'
import FormWrapper from '../components/FormWrapper'
import FormUsePasswordInput from '../components/FormUsePasswordInput'
import FormStatus from '../components/FormStatus'
import SubmitButton from '../components/SubmitButton'
import { useFrankentangel } from '../frankentangel'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

const PasswordReset = props => {
  const frankentangel = useFrankentangel()
  // const { token } = useParams()
  const token = useSelector(state => state.pwreset.token)
  const [passwordState, setPasswordState] = useState({
    password: '',
    confirmPassword: '',
    strength: 0,
    matching: true
  })

  const history = useHistory()

  const submitResetFunction = async values => {
    await frankentangel.runAction('users', 'changeTempPassword', { ...values, token })
    history.replace('/login')
  }

  return (
    <MainLayout fullheight centered>
      <Helmet>
        <title>Reset Password - TAA Music</title>
      </Helmet>
      <div className='container has-text-centered'>
        <div className='columns is-centered'>
          <div className='column is-one-third'>
            <div className='box has-text-left has-background-purple login-page'>
              <h1 className='title has-text-purple has-text-weight-light'>Create new password</h1>
              <FormWrapper
                fields={yup.object({
                  password: yup.string('Letters only').required('Required').min(2, 'Min. 2 chars').max(50, 'Max. 50 chars')
                })}
                submit={submitResetFunction}
              >
                <FormUsePasswordInput passwordState={passwordState} setPasswordState={setPasswordState} />
                <SubmitButton buttonText='Create' />
                <FormStatus />
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default PasswordReset
