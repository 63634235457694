import React from 'react'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import { useHistory } from 'react-router-dom'

import MainLayout from '../containers/MainLayout'
import Gradients from '../components/Home/Gradients'

const NotFound404 = props => {
  const history = useHistory()

  return (
    <MainLayout fullheight centered>
      <Helmet>
        <title>Page not found - TAA Music</title>
      </Helmet>
      <Gradients />
      <div className='container'>
        <div className='columns is-centered not-found-page'>
          <div className='column is-full'>
            <h1 className='has-text-centered gradient-text'>¯\_(ツ)_/¯</h1>
            <p className='has-text-centered gradient-text'>Page Not Found</p>
            <p className='has-text-centered'>The page you were looking for does&nbsp;not&nbsp;exist</p>
            <p className='has-text-centered'>
              <button type='button' onClick={() => history.push('/')} className='submitButton is-inline-block'>
                <span>Back to Home</span>
                <FontAwesomeIcon icon={faAngleRight} size='2x' />
              </button>
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default NotFound404
