import React, { useState } from 'react'
import { faCaretLeft, faDownload } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdmZip from 'adm-zip'

import usePdfify from './usePdfify'

import { useAction, useDocument, useFrankentangel } from '../frankentangel'
import CommercialsList from './CommercialsList'
import Button from './Button'
import { useDispatch, useSelector } from 'react-redux'
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons'

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const CommercialsTable = (props) => {
  const settings = useSelector(state => state.commercials)
  const frankentangel = useFrankentangel()

  const [week, , weekLoading] = useAction('commercial-weeks', 'getCommercials', { id: settings.week, accountId: settings.station })
  const [account, , accountLoading] = useDocument('accounts', settings.station)

  const hasCommercials = Boolean(week && week.commercials.length)

  // const openWeek = async ({ week, account }) => {
  //   const weekData = await frankentangel.runAction('commercial-weeks', 'getCommercials', { id: Number(week), accountId: Number(account) })

  //   if (weekData.error) throw new Error(weekData.error)
  //   setWeek(weekData)
  //   setAccountId(Number(account))

  //   console.log(weekData)
  // }

  const weekDays = hasCommercials
    ? days.map(day => {
        return week.commercials.map(comm => comm.doc).map(commercial => {
          const occurrences = commercial.days.filter(occurrence => occurrence.day === day)

          return occurrences.map(occurrence => {
            return { occurrence, commercial }
          })
        }).flat()
      })
    : [[], [], [], [], [], [], []]

  const [pdfifyElement, pdfify] = usePdfify()

  const printCalendar = async () => {
    const pdf = await pdfify(
      <>
        <div className='commercials-form__logo-row has-text-centered mt-4 mb-3'>
          <img className='commercials-form__logo' src='https://cdn.taamusic.com/taa-logo-color-300.png' alt='' />
        </div>
        <h1 className='title has-text-centered'>TAA Music commercials for week of {week.monday}</h1>
        <h2 className='subtitle has-text-centered mb-4'>For {account ? `${account.callsign} ${account.name}` : '(loading...)'}</h2>
        <CommercialsList week={week} weekDays={weekDays} isPrint />
      </>
    )

    // console.log('got pdf', pdf, window.URL.createObjectURL(new window.Blob([pdf])))

    return pdf
  }

  const [downloadProgress, setDownloadProgress] = useState({
    downloading: false,
    total: 0,
    current: 0
  })

  const downloadAll = async () => {
    setDownloadProgress({ downloading: true, total: week.commercials.length * 3 + 2, current: 0 })

    // no await, don't hold up the downlaod
    frankentangel.runAction('commercial-weeks', 'logDownload', { id: settings.week, accountId: settings.station }).catch(err => console.error('commercial logDownload', err))

    const [pdf, commercials] = await Promise.all([
      (async () => {
        const calendar = await printCalendar()
        setDownloadProgress(progress => ({ ...progress, current: progress.current + 1 }))
        return calendar
      })(),
      Promise.all(week.commercials.map(comm => comm.doc).map(async commercial => {
        const downloadUrl = await frankentangel.runAction('commercials', 'getDownloadLink', { id: commercial.id, accountId: account.id })
        setDownloadProgress(progress => ({ ...progress, current: progress.current + 1 }))
        // console.log('commercial url', commercial, downloadUrl)

        if (!downloadUrl.ok) throw new Error(`Something went wrong with the commercial URL: ${downloadUrl.error}`)

        const commercialResult = await window.fetch(downloadUrl.url)

        setDownloadProgress(progress => ({ ...progress, current: progress.current + 1 }))
        return {
          commercial,
          file: Buffer.from(await commercialResult.arrayBuffer())
        }
      }))
    ])

    const zip = new AdmZip()
    const addFileAsync = (...args) => new Promise((resolve, reject) => setTimeout(() => {
      try {
        const result = zip.addFile(...args)
        resolve(result)
      } catch (err) {
        reject(err)
      }
    }))

    zip.addFile('_Weekly Overview.pdf', pdf)
    for (const { file, commercial } of commercials) {
      const filename = `${commercial.isci}.mp3`
      await addFileAsync(filename, file)
      setDownloadProgress(progress => ({ ...progress, current: progress.current + 1 }))

      zip.getEntry(filename).header.method = 0 // you can't compress an mp3, it's already high-entropy
    }

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new window.Blob([zip.toBuffer()]))
    link.download = `TAA-${account.callsign}-${week.actualDate} - Commercials for Week of ${week.monday}.zip`
    link.click()

    setDownloadProgress({ downloading: false, total: 0, current: 0 })
  }

  const downloadOne = (id, isci) => async () => {
    const downloadUrl = await frankentangel.runAction('commercials', 'getDownloadLink', { id: id, accountId: account.id })

    if (!downloadUrl.ok) throw new Error(`Something went wrong with the commercial URL: ${downloadUrl.error}`)

    const commercialResult = await window.fetch(downloadUrl.url)
    const commercial = Buffer.from(await commercialResult.arrayBuffer())

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new window.Blob([commercial]))
    link.download = `${isci}.mp3`
    link.click()
  }

  const dispatch = useDispatch()

  return (
    <div className='container'>
      {
        week
          ? (
            <>
              {
                props.isPrint
                  ? undefined
                  : (
                    <h2 className='subtitle week-selector__back-button mt-4'>
                      <a className='week-selector__back-link' onClick={() => dispatch({ type: props.backAction })}>
                        <span className='icon'>
                          <FontAwesomeIcon icon={faCaretLeft} />
                        </span>
                        <span>
                          Back to Week Selection
                        </span>
                      </a>
                    </h2>
                    )
              }
              <div className='level mt-2 mb-4'>
                <div className='level-left'>
                  <div>
                    <h1 className='title mb-0'>Commercials for week of {new Date(week.actualDate.replace(/-/g, '/') + ' 0:00').toLocaleString('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })}</h1>
                    {
                      (!week.published)
                        ? (
                          <p className='help is-danger mt-0'>This week has not been published</p>
                          )
                        : undefined
                    }
                    <h2 className='subtitle mt-0'>For {account ? `${account.callsign} ${account.name}` : '(loading...)'}</h2>
                  </div>
                </div>
                <div className='level-right'>
                  {
                    hasCommercials
                      ? (
                        <div>
                          <div>
                            <Button classes='is-primary m-0' style={{ width: '12em' }} onClick={downloadAll}>
                              <span className='icon'>
                                <FontAwesomeIcon icon={faDownload} />
                              </span>
                              <span>
                                Download All
                              </span>
                            </Button>
                          </div>
                          {
                            downloadProgress.downloading
                              ? (
                                <progress className='progress is-success mt-1' value={downloadProgress.current} min={0} max={downloadProgress.total} />
                                )
                              : undefined
                          }
                        </div>
                        )
                      : undefined
                  }
                </div>
              </div>
              {
                hasCommercials
                  ? (
                    <CommercialsList week={week} weekDays={weekDays} downloadOne={downloadOne} />
                    )
                  : (
                    <div className='has-text-centered m-6'>
                      No commercials available for the selected week
                    </div>
                    )
              }
            </>
            )
          : (
            <div className='has-text-centered m-6'>
              {
                weekLoading || accountLoading
                  ? (
                    <>
                      <span className='icon'>
                        <FontAwesomeIcon icon={faSyncAlt} spin />
                      </span>
                      <span>
                        Loading...
                      </span>
                    </>
                    )
                  : 'No week selected'
              }
            </div>
            )
      }
      {pdfifyElement}
    </div>
  )
}

export default CommercialsTable
