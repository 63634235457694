const initialState = {
  token: null
}

const pwresetReducer = (state = initialState, action = {} /* shut up eslint */) => {
  const type = action.type

  if (type === 'pwreset.token') return { ...state, token: action.token }

  return state
}

export default pwresetReducer
