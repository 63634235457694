import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import FormContext from './FormContext'

const SubmitButton = props => {
  const { classes } = props
  const formProps = useContext(FormContext)

  return (
    <button
      type='submit'
      className={classNames('submitButton button', { 'is-loading': formProps.isSubmitting }, classes)}
      disabled={formProps.isSubmitting}
    >
      <span>{props.buttonText || 'Submit'}</span>
      <FontAwesomeIcon icon={['fal', 'angle-right']} size='2x' />
    </button>
  )
}

export default SubmitButton
