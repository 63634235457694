import { faSyncAlt, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useList } from '../frankentangel'
import classNames from 'classnames'

const Week = (props) => {
  const doc = props.doc
  const dispatch = useDispatch()

  const weekDate = new Date(doc.actualDate.replace(/-/g, '/'))

  const endOfWeek = new Date(weekDate)
  endOfWeek.setDate(endOfWeek.getDate() + 7)

  const isUnavailable = props.pastOnly ? (new Date() < endOfWeek) : false

  const notice = isUnavailable
    ? (
      <div className='week-selector__week-notice'>
        <span className='icon'>
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
        <span>
          Not Yet Available
        </span>
      </div>
      )
    : props.notice && props.notice(doc)
  const noticeContent = notice && notice._ghostNotice ? notice.content : notice
  const isNotice = notice && !notice._ghostNotice

  return (
    <button
      className={classNames('week-selector__week button', !isUnavailable && (isNotice ? 'is-primary' : 'is-link'))}
      disabled={isUnavailable}
      onClick={() => dispatch({ type: props.setAction, week: doc.id })}
    >
      <div className='week-selector__week-title'>{weekDate.toLocaleString('en-us', { month: 'short', day: 'numeric' }).toUpperCase()}</div>
      {noticeContent}
    </button>
  )
}

const WeekSelector = (props) => {
  const user = props.user
  const accountId = useSelector(state => state[props.system].station)
  const [Paginator, list, error, loading] = useList(
    'commercial-weeks',
    'listWithStatus',
    props.downloadableOnly ? { downloadableOnly: !(user && user.isAdmin), accountId } : { accountId }
    // user && user.isAdmin ? 'list' : 'listWithStatus',
    // user && user.isAdmin ? {} : (props.downloadableOnly ? { downloadableOnly: true, accountId } : { accountId })
  )

  const months = list
    .map(entry => entry.doc.actualDate.slice(0, 7)) // reduce entries to months, such as 2021-02
    .filter((value, index, array) => array.indexOf(value) === index) // deduplicate months
    .map(month => ({
      name: new Date(month.replace(/-/g, '/') + '/01').toLocaleString('en-us', { month: 'long', year: 'numeric' }),
      id: month,
      weeks: list.filter(entry => entry.doc.actualDate.startsWith(month))
    }))

  const dispatch = useDispatch()

  return (
    <div className='week-selector'>
      <h1 className='title has-text-centered'>{props.title}</h1>
      <h2 className='subtitle has-text-centered'>{props.subtitle || 'Select Week'}</h2>
      <h2 className='subtitle week-selector__back-button mx-4 mt-6'>
        <a className='week-selector__back-link' onClick={() => dispatch({ type: props.backAction })}>
          <span className='icon'>
            <FontAwesomeIcon icon={faCaretLeft} />
          </span>
          <span>
            Back to Call Letters
          </span>
        </a>
      </h2>
      <div className='week-selector__weeks mx-4 my-3'>
        {
          error
            ? <p className='has-text-centered'>Error loading list of weeks: {error.message ? error.message : error}</p>
            : (
              <Paginator>
                {
                  months.map(month => (
                    <Fragment key={month.id}>
                      <h2 className='week-selector__month-title subtitle has-text-link mt-3 mb-2'>{month.name}</h2>
                      <div className='week-selector__weeks-list'>
                        {
                          month.weeks.map(entry => <Week {...props} doc={entry.doc} key={entry.id} />)
                        }
                      </div>
                    </Fragment>
                  ))
                }
                {
                  loading
                    ? (
                      <p className='has-text-centered'>
                        <span className='icon'>
                          <FontAwesomeIcon icon={faSyncAlt} spin />
                        </span>
                        <span>
                          Loading weeks...
                        </span>
                      </p>
                      )
                    : undefined
                  }
              </Paginator>
              )
        }
      </div>
    </div>
  )
}

export default WeekSelector
