import React from 'react'
// import { faDownload } from '@fortawesome/pro-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Button from './Button'

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const CommercialsList = (props) => {
  // const { weekDays, week, isPrint, downloadOne } = props
  const { weekDays } = props

  return (
    <>
      <div className='commercials-form__calendar-title'>Calendar View</div>
      <div className='commercials-form__calendar'>
        <div className='commercials-form__columns'>
          {
            days.map((day, index) => {
              const occurrences = weekDays[index]

              return (
                <div className='commercials-form__day' key={day}>
                  <div className='commercials-form__column-title'>{day}</div>
                  <div className='commercials-form__column-list'>
                    {
                      occurrences.map((occurrence, index) => (
                        <div className='commercials-form__occurrence' key={index}>
                          <div className='commercials-form__occurrence-title'>
                            {occurrence.commercial.isci}
                          </div>
                          <div className='commercials-form__occurrence-isci'>
                            <strong>Name:</strong> <span>{occurrence.commercial.name}</span>
                          </div>
                          <div className='commercials-form__occurrence-duration'>
                            <strong>Duration:</strong> 0{occurrence.commercial.duration}
                          </div>
                          {
                            occurrence.occurrence.comment
                              ? (
                                <>
                                  <div className='commercials-form__occurrence-comment-title'>
                                    Occurrence Instructions:
                                  </div>
                                  <div className='commercials-form__occurrence-comment'>
                                    {occurrence.occurrence.comment}
                                  </div>
                                </>
                                )
                              : undefined
                          }
                          {
                            occurrence.commercial.instructions
                              ? (
                                <>
                                  <div className='commercials-form__occurrence-comment-title'>
                                    Commercial Instructions:
                                  </div>
                                  <div className='commercials-form__occurrence-comment'>
                                    {occurrence.commercial.instructions}
                                  </div>
                                </>
                                )
                              : undefined
                          }
                        </div>
                      ))
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      {/* <div className='commercials-form__isci-list'>
        <div className='commercials-form__isci-list-title'>
          List by ISCI codes
        </div>
        <div className='commercials-form__isci-list-body'>
          {
            week.commercials.map(comm => comm.doc).map(commercial => {
              return (
                <div className='commercials-form__isci-list-entry' key={commercial.isci}>
                  <div className='commercials-form__isci-list-top-row'>
                    <div className='commercials-form__isci-list-top-row-left'>
                      <div className='commercials-form__isci-list-entry-title'>
                        {commercial.isci}
                      </div>
                      <div className='commercials-form__isci-list-entry-name'>
                        {commercial.name}
                      </div>
                      <div className='commercials-form__isci-list-entry-occurrences'>
                        To be played {commercial.days.length} times
                      </div>
                    </div>
                    <div className='commercials-form__isci-list-top-row-middle'>
                      <div className='commercials-form__isci-list-entry-duration'>
                        Duration: 0{commercial.duration}
                      </div>
                      <div className='commercials-form__isci-list-entry-language'>
                        Language: {commercial.language}
                      </div>
                    </div>
                    {
                      !isPrint
                        ? (
                          <div className='commercials-form__isci-list-top-row-right'>
                            <div className='commercials-form__isci-list-entry-download'>
                              <Button className='button is-small my-1' onClick={downloadOne(commercial.id, commercial.isci)}>
                                <span className='icon'>
                                  <FontAwesomeIcon icon={faDownload} />
                                </span>
                                <span>
                                  Download
                                </span>
                              </Button>
                            </div>
                          </div>
                          )
                        : undefined
                    }
                  </div>
                  <div className='commercials-form__isci-list-entry-body'>
                    <div className='commercials-form__isci-list-entry-comment'>
                      <div className='commercials-form__isci-list-entry-comment-title'>
                        Instructions
                      </div>
                      <div className='commercials-form__isci-list-entry-comment-body'>
                        {commercial.instructions}
                      </div>
                    </div>
                    <div className='commercials-form__isci-list-entry-occurrence-list'>
                      <div className='commercials-form__isci-list-entry-occurrence-list-title'>
                        Occurrences / Run Days
                      </div>
                      <div className='commercials-form__isci-list-entry-occurrence-list-body'>
                        {
                          commercial.days.map((runDay, index) => {
                            return (
                              <div className='commercials-form__isci-list-entry-run-day' key={index}>
                                <div className='commercials-form__isci-list-entry-run-day-day'>
                                  {runDay.day}
                                </div>
                                <div className='commercials-form__isci-list-entry-run-day-comment'>
                                  {runDay.comment || 'No special instructions'}
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div> */}
    </>
  )
}

export default CommercialsList
