import React from 'react'

const Gradients = () => (
  <>
    <svg width='0' height='0'>
      <defs>
        <linearGradient id='main-icon-gradient' gradientTransform='rotate(90)'>
          <stop offset='0%' style={{ stopColor: 'rgb(1, 175, 182)', stopOpacity: 1 }} />
          <stop offset='100%' style={{ stopColor: 'rgb(205, 222, 85)', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
    </svg>
    <svg width='0' height='0'>
      <defs>
        <linearGradient id='main-icon-gradient-variant'>
          <stop offset='0%' style={{ stopColor: 'rgb(1, 175, 182)', stopOpacity: 1 }} />
          <stop offset='100%' style={{ stopColor: 'rgb(205, 222, 85)', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
    </svg>
  </>
)

export default Gradients
