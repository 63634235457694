import { faSyncAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import { useList } from '../frankentangel'
import classNames from 'classnames'

const Station = (props) => {
  const doc = props.doc
  const dispatch = useDispatch()

  const notice = props.notice && props.notice(doc)

  return (
    <button className={classNames('station-selector__station button', notice ? 'is-primary' : 'is-link')} onClick={() => dispatch({ type: props.setAction, station: doc.id })}>
      <div className='station-selector__station-call'>{doc.callsign}</div>
      <div className='station-selector__station-name'>{doc.name}</div>
      {notice}
    </button>
  )
}

const StationSelector = (props) => {
  const user = props.user
  const [Paginator, list, error, loading] = useList(
    'accounts',
    (user && user.isAdmin) ? 'list' : 'listWithWeekStatus',
    (user && user.isAdmin) ? {} : { userId: user?.id || 0 }
  )

  return (
    <div className='station-selector'>
      <h1 className='title has-text-centered'>{props.title}</h1>
      <h1 className='subtitle has-text-centered'>{props.subtitle || 'Select Call Letters'}</h1>
      <div className='station-selector__stations mx-4 my-6'>
        {
          error
            ? <p className='has-text-centered'>Error loading list of stations: {error.message ? error.message : error}</p>
            : (
              <Paginator>
                {
                  <div className='station-selector__stations-list'>
                    {
                      list
                        .filter(station => station.doc.type === 'Radio Station')
                        .map(entry => <Station {...props} doc={entry.doc} key={entry.id} />)
                    }
                  </div>
                }
                {
                  loading
                    ? (
                      <p className='has-text-centered'>
                        <span className='icon'>
                          <FontAwesomeIcon icon={faSyncAlt} spin />
                        </span>
                        <span>
                          Loading stations...
                        </span>
                      </p>
                      )
                    : undefined
                }
              </Paginator>
              )
        }
      </div>
    </div>
  )
}

export default StationSelector
