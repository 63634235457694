import React from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Feature = props => {
  const { icon, iconProps, title, content, ctaText } = props
  const history = useHistory()

  const handleClick = () => history.push('/contact')

  return (
    <div className='features-box is-flex'>
      <div className='features-box__icon-wrapper'>
        <FontAwesomeIcon icon={icon} size='5x' className='features-box__icon gradient-icon-vertical' {...iconProps} />
      </div>
      <div className='features-box__content'>
        {title && <p className='features-box__title has-text-teal is-size-4'>{title}</p>}
        <div className='features-box__text has-text-taa-grey is-slightly-bigger'>
          {content}
        </div>
        {ctaText && <button type='button' onClick={handleClick} className='features-box__cta is-uppercase is-size-7 has-text-white py-2 mt-3'>{ctaText}</button>}
      </div>
    </div>
  )
}

export default Feature
