import React from 'react'

const MainSVG = props => {
  return (
    <>
      <object type='image/svg+xml' data='https://cdn.taamusic.com/soundwaves-animated.svg'>
        <img src='https://cdn.taamusic.com/soundwaves-animated.svg' alt='Sound Waves' />
      </object>
    </>
  )
}

export default MainSVG
