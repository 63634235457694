import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import classNames from 'classnames'
import AdmZip from 'adm-zip'

// import Track from '../Track'
import useObjectDelete from '../../useObjectDelete'
import AsyncTrack from '../AsyncTrack'
import useDownloadDisclaimer from '../useDownloadDisclaimer'
import { useFrankentangel } from '../../../frankentangel'
import Button from '../../Button'

const Playlist = props => {
  const { currentUser, playlist, copyToPlaylist, indexOffset, userContract } = props
  const [collapsed, setCollapsed] = useState(true)
  const [deleteModal, handleDelete] = useObjectDelete()
  const [disclaimerModal, showDisclaimer] = useDownloadDisclaimer()

  const frankentangel = useFrankentangel()
  const [downloadProgress, setDownloadProgress] = useState({
    downloading: false,
    total: 0,
    current: 0
  })

  const download = async ev => {
    ev.stopPropagation()
    if (downloadProgress.downloading) return

    if (!await showDisclaimer()) return

    try {
      setDownloadProgress({ downloading: true, total: playlist.doc.songs.length * 4 + 1, current: 0 })

      const downloadUrls = await frankentangel.runAction('playlists', 'getDownloadUrl', { id: playlist.doc.id, contract: userContract })
      if (downloadUrls.error) throw new Error(`Something went wrong with the download URLs: ${downloadUrls.error}`)

      setDownloadProgress(progress => ({ ...progress, current: progress.current + downloadUrls.length }))

      const songs = await Promise.all(downloadUrls.map(async ({ song, downloadUrl }, index) => {
        const metadataPromise = frankentangel.viewDocument('song', song.id)
        if (!downloadUrl['320_check']) throw new Error(`Something went wrong with one of the download URLs: ${downloadUrl.error}`)

        const downloadResult = await window.fetch(downloadUrl['320'])
        setDownloadProgress(progress => ({ ...progress, current: progress.current + 1 }))

        const songFile = Buffer.from(await downloadResult.arrayBuffer())

        const metadata = await metadataPromise
        setDownloadProgress(progress => ({ ...progress, current: progress.current + 1 }))

        return { song: metadata, songFile, index }
      }))

      const zip = new AdmZip()
      const addFileAsync = (...args) => new Promise((resolve, reject) => setTimeout(() => {
        try {
          const result = zip.addFile(...args)
          resolve(result)
        } catch (err) {
          reject(err)
        }
      }))

      for (const { song, songFile, index } of songs) {
        const filename = `${String(index + 1).padStart(2, '0')}-${song.song_title}-${song.album.library.library_name}-${song.album.album_name}.mp3`
        await addFileAsync(filename, songFile)
        setDownloadProgress(progress => ({ ...progress, current: progress.current + 1 }))

        zip.getEntry(filename).header.method = 0 // turn off compression (ugly hack but it works)
      }

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new window.Blob([zip.toBuffer()]))
      link.download = `${playlist.doc.name}.zip`
      link.click()

      setDownloadProgress({ downloading: false, total: 0, current: 0 })
    } catch (err) {
      setDownloadProgress({ downloading: false, total: 0, current: 0 })
      throw err
    }
  }

  const remove = ev => {
    ev.stopPropagation()
    handleDelete('playlist', 'playlists', playlist.doc.id, playlist.doc.name)
  }

  const renderTracks = playlist.doc.songs.map((song, index) => {
    return (
      <AsyncTrack
        key={song.id}
        id={song.id}
        index={indexOffset + index}
        currentUser={currentUser}
        playlist={playlist}
        copyToPlaylist={copyToPlaylist}
        userContract={userContract}
      />
    )
  })

  return (
    <div className={classNames('explore__playlists-playlist-wrapper', 'column', 'is-full', { 'is-active': !collapsed })}>
      <div className='explore__playlists-playlist-head' onClick={() => setCollapsed(!collapsed)}>
        <div className='explore__playlists-playlist-title'>
          <span className='icon'><FontAwesomeIcon icon={faAngleDown} rotation={collapsed ? 0 : 180} size='2x' /></span>{playlist.doc.name}
        </div>
        <div className='explore__playlists-playlist-controls'>
          {
            downloadProgress.downloading
              ? (
                <progress className='progress is-success mb-0 mr-2' style={{ display: 'inline', width: '4em' }} value={downloadProgress.current} min={0} max={downloadProgress.total} />
                )
              : undefined
          }
          <Button noClass type='button' onClick={download}><FontAwesomeIcon icon={downloadProgress.downloading ? ['fal', 'sync-alt'] : ['fal', 'cloud-download']} spin={downloadProgress.downloading} /></Button>
          <Button noClass type='button' onClick={remove}><FontAwesomeIcon icon={['fal', 'trash-alt']} /></Button>
        </div>
      </div>
      <div className='explore__playlists-playlist-tracks'>
        {renderTracks}
      </div>
      {deleteModal}
      {disclaimerModal}
    </div>
  )
}

export default Playlist
