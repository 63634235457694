import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { useDispatch } from 'react-redux'
import Button from './Button'
import { useFrankentangel } from '../frankentangel'
import useModal from './useModal'

const AffidavitSubmitScreen = ({ user, week, account, playbacks }) => {
  const dispatch = useDispatch()
  const frankentangel = useFrankentangel()

  const readableWeek = new Date(week?.actualDate?.replace(/-/g, '/') + ' 0:00').toLocaleString('en-us', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })

  const [modal, showModal] = useModal()

  const handleSubmit = async () => {
    const results = await frankentangel.runAction('affidavits', 'submit', {
      account: account.id,
      week: week.id,
      playbacks: playbacks.map(({ day, time, isci }) => ({ day, time, isci }))
    })
    const errors = results.filter(result => result.ok === false)
    const successes = results.filter(result => result.ok === true)

    if (errors.length) {
      await showModal(
        'Error Submitting Certificate of Performance',
        `The following errors were encountered during submitting the Certificate of Performance:\n\n${
          errors.map(result => result.error).join('\n')
        }\n\nIf an incorrect Certificate of Performance has been submitted as a result of this error, please contact us through our Contact Form or call 940-365-9680.`
      )
    }

    if (successes.length) dispatch({ type: 'affidavits.submissionSuccess' })
  }

  return (
    <div className='affidavit-submit-screen'>
      <h1 className='title has-text-centered'>Submit Certificate of Performance</h1>
      <h2 className='subtitle has-text-centered'>Legal Agreement</h2>
      <h2 className='subtitle week-selector__back-button mt-6'>
        <a className='week-selector__back-link' onClick={() => dispatch({ type: 'affidavits.exitSubmitting' })}>
          <span className='icon'>
            <FontAwesomeIcon icon={faCaretLeft} />
          </span>
          <span>
            Back to Affidavit Review
          </span>
        </a>
      </h2>
      <div className='content affidavit-submit-screen__content'>
        <p>
          I, {user?.firstName} {user?.lastName}, hereby certify that I am an employee or representative of {account?.callsign} {account?.name} and
          I am authorized to submit this statement on behalf of the company.
        </p>
        <p>
          Upon request, {account?.callsign} {account?.name} agrees to deliver TAA Music within three business days a copy of the operations log for
          the week of {readableWeek}.
        </p>
        <p>
          I hereby acknowledge and attest that all national sponsor commercials aired, unedited, as ordered or during the dates and times indicated.
        </p>
        <p>
          This Certificate of Performance is valid only for the week of {readableWeek}. The information is accurate and true and may be verified
          against the Operation Log of {account?.callsign} {account?.name} by TAA Music, Inc or its agents.
        </p>
      </div>
      <div className='has-text-centered mt-6'>
        <Button classes='is-primary' onClick={handleSubmit}>
          <span className='icon'>
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
          <span>
            Submit
          </span>
        </Button>
      </div>
      {modal}
    </div>
  )
}

export default AffidavitSubmitScreen
