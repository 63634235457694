import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import createAction from '../../../action'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Filter from './Filter'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

const Filters = props => {
  const { mobileGenreState, mobileGenreSwitch } = props
  const [genres, setGenres] = useState([])
  const [tempo, setTempo] = useState('')
  const state = useSelector(state => ({ songSearch: state.playerNFilters.songSearch, songGenres: state.playerNFilters.songGenres, songTempo: state.playerNFilters.songTempo }))
  const dispatch = useDispatch()

  const setStatus = str => {
    return state.songSearch.toLowerCase().includes(str.toLowerCase()) ? (state.songSearch.includes(`-${str}`) ? 'remove' : 'add') : 'base'
  }

  useEffect(() => {
    if (state.songGenres) setGenres(state.songGenres)
    if (state.songTempo) setTempo(state.songTempo)
    if (state.songTempo === '') setTempo('')
  }, [state.songGenres, state.songTempo])

  const clearTheFilters = () => {
    // const allFilters = [...genres, tempo]
    // let searchStr = state.songSearch
    // allFilters.forEach(filter => {
    //   searchStr = searchStr.replace((searchStr.includes(` -${filter}`) ? ` -${filter}` : `-${filter}`), '')
    //   searchStr = searchStr.replace((searchStr.includes(` ${filter}`) ? ` ${filter}` : filter), '')
    // })
    dispatch(createAction('playerNFilters.songSearch', { songSearch: '' }))
  }

  const renderGenres = genres.length !== 0 ? genres.map(genre => <Filter key={Math.random() * 234} title={genre.title} status={setStatus(genre.searchTerm)} techTitle={genre.searchTerm} />) : null
  const renderTempo = tempo !== '' ? <Filter key={Math.random() * 239} title={tempo} status={setStatus(tempo)} techTitle={tempo.replace('/', '-')} /> : null

  return (
    <div className={classNames('filters columns is-multiline', { showGenres: mobileGenreState })}>
      <div className='filters__genre-head column is-full is-family-secondary'>
        <span>Genre:</span>
        <button type='button' onClick={clearTheFilters} className='is-family-secondary is-uppercase'>Clear Filters</button>
        <button type='button' onClick={() => mobileGenreSwitch(!mobileGenreState)} className='is-family-secondary is-uppercase mobile-genre-close'>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className='filters__genres column is-full'>
        <div className='columns is-multiline'>
          {renderGenres}
        </div>
      </div>
      <div className='filters__genre-head filters__genre-head--tempo column is-full is-family-secondary'>
        <span>Tempo:</span>
      </div>
      <div className='filters__genres column is-full'>
        <div className='columns is-multiline'>
          {renderTempo}
        </div>
      </div>
    </div>
  )
}

export default Filters
