import React from 'react'
import { faRocketLaunch, faHandshake, faThunderstormSun, faChartLine } from '@fortawesome/pro-light-svg-icons'
import { Helmet } from 'react-helmet'

import MainLayout from '../containers/MainLayout'
import Feature from '../components/Home/Feature'
import Gradients from '../components/Home/Gradients'
import BackToTopSideButton from '../components/BackToTopSideButton'

const About = props => {
  return (
    <MainLayout alignstart fullheight innerClasses={['home-body']}>
      <Helmet>
        <title>About - TAA Music</title>
      </Helmet>
      <Gradients />
      <div className='container'>
        <div className='columns about-page'>
          <div className='column is-5 timeline-wrapper'>
            <div className='timeline'>
              <div className='timeline__event--left'>
                <p className='timeline__event-year is-family-secondary'>1973</p>
                <p className='timeline__event-title'>Startup Launched</p>
                <p className='timeline__event-content'>Toby and Dolores Arnold launch a startup aimed at producing radio station ID jingles. 8-Tracks and vinyl records are mainstream.</p>
              </div>
              <div className='timeline__event--right'>
                <p className='timeline__event-year is-family-secondary'>1974</p>
                <p className='timeline__event-title'>Toby Makes It Official</p>
                <p className='timeline__event-content'>Toby Arnold & Associates is incorporated. Primary office is located on Commander Dr. in Carrollton, TX.</p>
              </div>
              <div className='timeline__event--left'>
                <p className='timeline__event-year is-family-secondary'>1975</p>
                <p className='timeline__event-title'>Off to a Good Start</p>
                <p className='timeline__event-content'>Toby Arnold & Associates continues to expand. Stations IDs, advertiser jingles and music production are offered to radio stations.</p>
              </div>
              <div className='timeline__event--right'>
                <p className='timeline__event-year is-family-secondary'>1976 - 1980</p>
                <p className='timeline__event-title'>Convenience & Production</p>
                <p className='timeline__event-content'>More growth; ready-to-use music production libraries become standard. Cassettes become mainstream.</p>
              </div>
              <div className='timeline__event--left'>
                <p className='timeline__event-year is-family-secondary'>1980 - 1985</p>
                <p className='timeline__event-title'>Associates Make Major Contributions</p>
                <p className='timeline__event-content'>Many musicians, industry connections and associates of Toby Arnold contribute and collaborate in music production. Dallas is a nationwide hub for jingles and station IDs.</p>
              </div>
              <div className='timeline__event--right'>
                <p className='timeline__event-year is-family-secondary'>1985 - 1990</p>
                <p className='timeline__event-title'>Competitors & Friends</p>
                <p className='timeline__event-content'>Toby Arnold and Grady Sanders collaborate on an expansive, ready-made music production library concept. Compact Discs (CDs) become mainstream.</p>
              </div>
              <div className='timeline__event--left'>
                <p className='timeline__event-year is-family-secondary'>1990 - 2000</p>
                <p className='timeline__event-title'>Further Success</p>
                <p className='timeline__event-content'>TA&A continues to grow strong with 30-full time employees and over 1,000 subscribers. MP3s become mainstream.</p>
              </div>
              <div className='timeline__event--right'>
                <p className='timeline__event-year is-family-secondary'>2000 - 2005</p>
                <p className='timeline__event-title'>Tragedy Strikes</p>
                <p className='timeline__event-content'>Toby Arnold and Grady Sanders negotiate the future of TA&A. The company's headquarters are destroyed.  Dolores Arnold, founder, passes away.</p>
              </div>
              <div className='timeline__event--left'>
                <p className='timeline__event-year is-family-secondary'>2006 - 2010</p>
                <p className='timeline__event-title'>Recovery</p>
                <p className='timeline__event-content'>Toby Arnold passes away. Patricia Arnold becomes President. Grady Sanders becomes CEO and acquires the company. Susan Bedwell joins TA&A as COO / CFO and leads in rebuilding. Patricia Arnold retires. Digital technology has transformed the music industry.</p>
              </div>
              <div className='timeline__event--right'>
                <p className='timeline__event-year is-family-secondary'>2010 - 2015</p>
                <p className='timeline__event-title'>Highs & Lows</p>
                <p className='timeline__event-content'>The company goes fully digital. TA&A is formally dissolved and becomes a subsidiary of First American Holdings. Grady Sanders passes away. Susan Bedwell becomes CEO / COO.</p>
              </div>
              <div className='timeline__event--left'>
                <p className='timeline__event-year is-family-secondary'>2015 - 2020</p>
                <p className='timeline__event-title'>A New Path Forward</p>
                <p className='timeline__event-content'>The company quietly expands it's music libraries. Troy Powers becomes Chief Creative Engineer. Ashley Hiller joins as Vice President of Operations.  The pandemic of 2020 reinforces a path of digital strategy. TAA Music launches a new website and online systems. Cloud-streamed music becomes mainstream.</p>
              </div>
            </div>
          </div>
          <div className='column is-7 about-content'>
            <BackToTopSideButton watchTargetSelector='.about-content' />
            <Feature
              icon={faRocketLaunch}
              content={
                <>
                  <p>Toby and Dolores Arnold, a husband-and-wife team, launched Toby Arnold & Associates in 1973. Toby Arnold had been a sales manager at PAMS in Dallas, TX. <a href='http://www.pams.com/index.html' target='_blank' rel='noreferrer'>PAMS</a> was a well-known producer of station identification jingles during the 50s, 60s & 70s. Toby carried across his friendships with PAMS arranger Euel Box and independent producer Dick Starr when he started his new enterprise. He also brought along his Rolodex of radio clients of whom he had many. By 1974, Toby Arnold & Associates was incorporated in the state of Texas with an office on Commander Drive in Carrollton, a city in the Dallas-Ft. Worth metroplex.</p>
                  <p>In the early years, Toby and Dolores focused on producing station identification jingles, but they quickly moved into theme music, advertiser jingles and ready-to-use production music libraries. They targeted national accounts, but also Canada, South America and Europe through subscription and barter agreements.</p>
                </>
              }
            />
            <Feature
              icon={faHandshake}
              content={
                <>
                  <p>Toby & Dolores worked with many writers and singers, many of which were freelance musicians and independent contractors, few were directly affiliated with any company. It is from here that the 'Associates' for the Toby Arnold name was derived.</p>
                  <p>In the 70s and 80s, Dallas was the hub of the jingles and station ID industry. Cross collaborations occurred frequently between industry friends and connections of Toby. Tom Merriman, Euel Box, Bill Meeks, and Dick Starr were all frequent contributors. Grady Sanders, who was also in the jingles business, was a sales manager for Pepper Sound Studios and a direct competitor of Toby during his time at PAMS. However, the two men were close friends and collaborated on a ready-to-use production library concept. Mr. Sanders was even a silent investor in Toby Arnold & Associates, Inc. with an aim in helping ensure the company’s success. Sanders and Arnold spent many hours perfecting the production music subscription model. They also copyrighted all music produced by the company with the consent of all associates and contributors.</p>
                </>
              }
            />
            <Feature
              icon={faThunderstormSun}
              content={
                <>
                  <p>During the 90s and after 27 years of success and growth, Toby Arnold & Associates began to go through changes. With her health falling rapidly, Dolores Arnold passed away in early 2004. Subsequently, in 2005 Toby Arnold re-married and Patricia Arnold joined the company. During this time Toby Arnold's health began to decline. He once again turned to Grady Sanders with a request to evaluate the business and make plans for an acquisition by Grady.</p>
                  <p>Negotiations were suddenly halted as disaster struck. The company headquarters in Carrollton, TX were struck by lightning resulting in a complete loss of the building. A significant salvage operation was conducted to save library masters and historical records of the company. Toby and Grady are forced to put their plans for acquisition on hold while the building is rebuilt and the company is restructured.</p>
                  <p>The following year brought further changes. Toby Arnold, after battling a lengthy illness, passes away in 2006. Patricia Arnold becomes President while Grady Sanders formally accepts a position as CEO. Susan Bedwell joins the executive team as COO & CFO. Susan takes a leadership role in completing the company's recovery.</p>
                </>
              }
            />
            <Feature
              icon={faChartLine}
              content={
                <>
                  <p>Beginning in 2007, with the company recovering from previous years, TA&A charts a new course towards digital strategy. Key decisions are made that will enable employees to work from home and let subscribers do business online. In 2008, the company launched an initiative to collect input from clients, in-house staff and leverage existing technology with the goal of creating a company website and subscriber portal. Due to the vast number of copyrighted works and musical inventory, the project takes over two years to complete. In 2009, Grady Sanders completes his acquisition of Toby Arnold & Associates, Inc. while Patricia Arnold decides to retire.</p>
                  <p>Following the path towards a digital future and a virtual office, TA&A's first major website is launched in 2010. This creates an e-commerce client portal with music library access, barter commercial downloads and online "proof of performance" submissions. In June 2010, Toby Arnold & Associates Inc is formally dissolved. Two entities now operate under Grady Sanders' First American Holdings Corp. - TAA Music and Toby Arnold Sound Studios.</p>
                  <p>Keeping with their digital promise, in 2012, TAA Music employees are now working from home or independent studios across multiple states. The TAA Music library continued to grow with new musical formats and more freedom for the company's musical creative team.</p>
                  <p>In 2013, Susan Bedwell becomes the company's CEO / COO, in part due to the declining health of Grady Sanders. Grady becomes Chairman of the Board but also continues as advisor and mentor. Troy Powers, a long time contributor and Sound Engineer, becomes Chief Creative Engineer. In 2015, Grady Sanders passes away, but the company experiences a seamless transition due to Grady's contributions during the previous two and half years.</p>
                  <p>TAA Music officially becomes a female owned and operated company in 2015. In the following years, Susan and the team continuously grow the music library with new concepts, libraries and albums. As the music library grows, it becomes increasingly clear a fresh perspective and new technology for the company's digital strategy must be planned.</p>
                  <p>During 2019, due to changes in technology, shifts in the music industry and evolving requirements by subscribers, a new initiative is launched to develop a modern digital management system, website and client portal. The project gets off to a good start but encounters significant challenges, delays and unreliable solutions. During this time, Ashley Hiller joins as Vice President of Operations.</p>
                  <p>As for many companies, 2020 was an exceptionally tough year. TAA Music was an early adopter of work from home mentality, it paid dividends in a year where the pandemic seemed to disrupt all corners of routine. The team consistently added more music to the digital library which now numbered over 1 million tracks. However, difficulties continued with the development of company's new digital systems. A tough decision was made to halt development and change course. The TAA team re-focused on client requirements, available technology and a fresh approach. Toby Arnold Sound Studios was retired as the company name. TAA Music became the official company name and the company is rebranded. The company ended the year on a positive note with the launch of their new digital systems, which include a radically new website, cloud-based music streaming, advanced music search, an innovative advertising & compliance system and new client management systems.</p>
                </>
              }
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default About
