import { createStore, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'

import frontEndReducer from './reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  frontEndReducer,
  composeEnhancers(
    applyMiddleware(ReduxThunk)
  )
)

export default store

window.addEventListener('beforeunload', (event) => {
  const state = store.getState()

  let hasWorkLeft = false

  if (window.location.pathname === '/affidavits' && state?.affidavits?.station && state?.affidavits?.week && state?.affidavits?.method) hasWorkLeft = true

  if (hasWorkLeft) {
    const confirmationMessage = 'Please confirm that you want to leave - data you have entered may not be saved'
    ;(event || window.event).returnValue = confirmationMessage
    return confirmationMessage
  }
})
