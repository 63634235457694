import React, { useState } from 'react'
import { faCaretLeft, faCheckCircle, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AffidavitInput from './AffidavitDesktop'
import useModal from './useModal'
import { useDispatch } from 'react-redux'

const AffidavitForm = ({ week, account, isciCodes, receivedPlaybacks, backAction, backText, reviewAction }) => {
  const hasCommercials = Boolean(Object.keys(isciCodes).length)

  const [controls] = useState({})

  const dispatch = useDispatch()

  // const handleReview = () => {
  //   const data = controls.getData()
  //   const commercials = week.commercials.map(row => row.doc)

  //   const isciCodes = {}

  //   for (const key in data.remainingPlaybacks) {
  //     isciCodes[key] = {
  //       logged: {
  //         list: [],
  //         days: [0, 0, 0, 0, 0, 0, 0],
  //         total: 0
  //       },
  //       remaining: data.remainingPlaybacks[key],
  //       commercial: commercials.filter(comm => comm.isci === key)[0] || null
  //     }
  //   }

  //   data.playbacks.forEach(playback => {
  //     const entry = isciCodes[playback.isci]

  //     entry.logged.list.push(playback)
  //     entry.logged.days[days.indexOf(playback.day)] += 1
  //     entry.logged.total += 1
  //   })

  //   setSubmissionData({ data: null, loading: false })
  //   setReviewData(isciCodes)
  // }

  // const handleSubmit = async () => {
  //   const playbacks = []

  //   for (const isci in reviewData) {
  //     for (const playback of reviewData[isci].logged.list) {
  //       playbacks.push({
  //         day: playback.day,
  //         time: playback.time,
  //         isci
  //       })
  //     }
  //   }

  //   setSubmissionData({ data: null, loading: true })

  //   const results = await frankentangel.runAction('affidavits', 'submit', {
  //     account: account.id,
  //     week: week.id,
  //     playbacks
  //   })

  //   setSubmissionData({ data: results, loading: false })
  // }

  const [currentIsci, setCurrentIsci] = useState(null)
  const filteredCommercial = (week && week.commercials.filter(comm => comm.doc.isci === currentIsci)[0])
  const currentCommercial = filteredCommercial ? filteredCommercial.doc : null

  const [modal, showModal] = useModal()

  const openTooltipModal = () => {
    showModal(
      'TAA Music Air Time Affidavit',
      <>
        <div className='block content'>
          <p>This form is designed for fast and efficient data entry of affidavit information.</p>
          <ol>
            <li>To start, with your mouse, click an ISCI code on the left. You can navigate with your keyboard from this point forward.</li>
            <li>Press the [Right-Arrow] on your keyboard to navigate into the weekday-hour-minute input area.</li>
            <li>With your keyboard, use the arrow keys to input the hour and minute on the day which the commerical was aired at your station.</li>
            <li>Example: To input that a commercial aired on Wednesday at 1:42pm. With your keyboard, highlight the hour input box next to Wednesday then hit "1" [Enter], "42" [Enter], highlight "PM" [Enter]</li>
            <li>You will see the time was added to Playbacks Logged.</li>
            <li>Press "Done" when all air times for a commercial have been logged. The system will automatically push the commerical to the bottom of the stack and continue with the next one.</li>
            <li>You can undo any air times by clicking the undo arrow on any playback logged.</li>
            <li>When all times have been entered, click submit at the bottom of the form.</li>
            <li>Pro Tips: Use the '+' and '-' keys to increase/decrease a number. You can also hold [Shift] while using the [Up] or [Down] arrows keys to increase/decrease a number.</li>
          </ol>
        </div>
      </>
    )
  }

  return (
    <div className='container'>
      {
        week
          ? (
            <>
              <h1 className='title has-text-centered' style={{ position: 'relative' }}>
                Week of {new Date(week.actualDate.replace(/-/g, '/') + ' 0:00').toLocaleString('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })}
                <button style={{ position: 'absolute', top: 0, right: 0 }} className='button is-primary' onClick={openTooltipModal}>
                  <span className='icon'>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                </button>
              </h1>
              <h2 className='subtitle has-text-centered mb-3'>Affidavits for {account ? `${account.callsign} ${account.name}` : '(loading...)'}</h2>
              {
                hasCommercials
                  ? (
                    <>
                      {/* <p className='help'>
                        Tooltip: Once clicked into an input field, use the arrow keys to navigate. After entering the time, select AM or PM and press Enter to log a playback.
                        Use the '+' and '-' keys to increase or decrease a number, or type the number in. Alternatively, you can also hold Shift and press the up and down
                        arrows to increase or decrease a number. If you have made a mistake, click the undo arrow on the logged playback to remove it from the list and
                        repopulate the input fields. Once you have added all playbacks for a given ISCI, press Done to automatically forward to the next one.
                      </p> */}
                      {
                        currentCommercial
                          ? (
                            <div className='block has-text-centered mb-0'>
                              <h1 className='title my-0'>{currentCommercial.name}</h1>
                              {currentCommercial.description ? <h2 className='subtitle my-0'>{currentCommercial.description}</h2> : undefined}
                              <h2 className='subtitle my-0'>{currentCommercial.agency.name} - {currentCommercial.isci}</h2>
                            </div>
                            )
                          : undefined
                      }
                      <div className='level my-4' style={{ maxWidth: '61em', margin: '0 auto' }}>
                        <div className='level-left'>
                          <h2 className='subtitle affidavit-review__back-button'>
                            <a className='affidavit-review__back-link' onClick={() => dispatch({ type: backAction })}>
                              <span className='icon'>
                                <FontAwesomeIcon icon={faCaretLeft} />
                              </span>
                              <span>
                                {backText}
                              </span>
                            </a>
                          </h2>
                        </div>
                        <div className='level-right'>
                          <button className='button is-primary' onClick={() => dispatch({ type: reviewAction })}>
                            <span className='icon'>
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </span>
                            <span>
                              Review
                            </span>
                          </button>
                        </div>
                      </div>
                      <AffidavitInput
                        isciCodes={isciCodes}
                        controls={controls}
                        onIsciChange={(isci) => setCurrentIsci(isci)}
                        receivedPlaybacks={receivedPlaybacks}
                      />
                    </>
                    )
                  : (
                    <div className='has-text-centered m-6'>
                      No affidavit available to be submitted for this account for the selected week
                    </div>
                    )
              }
            </>
            )
          : (
            <div className='has-text-centered m-6'>
              No week selected
            </div>
            )
      }
      {modal}
    </div>
  )
}

export default AffidavitForm
