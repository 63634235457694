import React from 'react'
import { Fade } from 'react-awesome-reveal'

const Metric = props => {
  const { number, category, delay } = props
  return (
    <div className='block metrics-type'>
      <Fade fraction={1} delay={delay}>
        <p className='big-number gradient-text has-text-centered'>{number}</p>
        <p className='metric-label is-uppercase has-text-centered has-text-purple is-family-secondary is-size-3-desktop'>{category}</p>
      </Fade>
    </div>
  )
}

export default Metric
