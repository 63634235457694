import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import MainLayout from '../containers/MainLayout'
import FormWrapper from '../components/FormWrapper'
import FormInputField from '../components/FormInputField'
import FormStatus from '../components/FormStatus'
import SubmitButton from '../components/SubmitButton'
import { useFrankentangel } from '../frankentangel'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

const Login = props => {
  const frankentangel = useFrankentangel()

  const history = useHistory()
  const dispatch = useDispatch()

  const submitFunction = async values => {
    const result = await frankentangel.runAction('users', 'login', values)
    // if (!result.ok && result.reason === 'passwordTemporary') history.replace(`/password-reset/${result.data.token}`) // don't expose this in the URL, that's horribly insecure
    if (!result.ok && result.reason === 'passwordTemporary') {
      dispatch({ type: 'pwreset.token', token: result.data.token })
      history.replace('/password-reset')
    }
    if (result.ok) history.replace('/')
  }

  return (
    <MainLayout fullheight centered>
      <Helmet>
        <title>Login - TAA Music</title>
      </Helmet>
      <div className='container has-text-centered'>
        <div className='columns is-centered'>
          <div className='column is-one-third'>
            <div className='box has-text-left has-background-purple login-page'>
              <h1 className='title has-text-purple has-text-weight-light'>Please log in</h1>
              <FormWrapper
                fields={yup.object({
                  email: yup.string('Letters only').required('Required').email('Please provide a valid email address'),
                  password: yup.string('Letters only').required('Required').min(2, 'Min. 2 chars').max(50, 'Max. 50 chars')
                })}
                submit={submitFunction}
              >
                <FormInputField name='email' type='email' label='EMAIL ADDRESS' />
                <FormInputField name='password' label='PASSWORD' type='password' />
                <SubmitButton buttonText='Login' />
                <FormStatus />
              </FormWrapper>
              <div className='block has-text-right'>
                <p>
                  <Link to='/pwreset'>RESET PASSWORD</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Login
