const xkcd1179withTZ = (timeZone = 'America/Chicago', date = null) => {
  const now = date ? new Date(date) : new Date()

  return `${
    now.toLocaleString('en-US', { timeZone, year: 'numeric' })
  }-${
    now.toLocaleString('en-US', { timeZone, month: 'numeric' }).padStart(2, '0')
  }-${
    now.toLocaleString('en-US', { timeZone, day: 'numeric' }).padStart(2, '0')
  }`
}

module.exports = xkcd1179withTZ
