import React, { useState } from 'react'
import { useFrankentangel, useList } from '../../frankentangel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import Button from '../Button'
import classNames from 'classnames'

const AddToPlaylist = props => {
  const { active, setActive, mode } = props
  const [selectedPlaylist, selectPlaylist] = useState(null)
  const [noPlaylistError, togglePlaylistError] = useState(true)
  const frankentangel = useFrankentangel()
  const [Paginator, results] = useList('playlists', 'list')

  const handleAddToPlaylist = async () => {
    if (!selectedPlaylist) {
      togglePlaylistError(false)
      return
    }
    if (!noPlaylistError) togglePlaylistError(true)
    await frankentangel.runAction('playlists', 'addToPlaylist', { song: active.trackId, playlist: Number(selectedPlaylist) })
    selectPlaylist(null)
    setActive({ show: false, trackId: null, playlistId: null })
  }

  const handleOnChangeAndBlur = val => {
    if (!noPlaylistError) togglePlaylistError(true)
    selectPlaylist(val)
  }

  let playlists

  if (mode === 'add') playlists = results ? results.filter(playlist => playlist.doc.songs.length < 50).map((playlist, index) => <p className={classNames({ 'is-selected': selectedPlaylist === playlist.doc.id })} key={`${index}-${playlist.doc.name}`} onClick={() => handleOnChangeAndBlur(playlist.doc.id)}>{playlist.doc.name}</p>) : null
  else playlists = results ? results.filter(result => (result.doc.id !== active.playlistId) && (result.doc.songs.length < 50)).map((playlist, index) => <p className={classNames({ 'is-selected': selectedPlaylist === playlist.doc.id })} key={`${index}-${playlist.doc.name}`} onClick={() => handleOnChangeAndBlur(playlist.doc.id)}>{playlist.doc.name}</p>) : null

  return (
    <div className={classNames('modal', {
      'is-active': active.show
    })}
    >
      <div className='modal-background' onClick={() => setActive({ show: false, trackId: null, playlistId: null })} />
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>{`${mode === 'copy' ? 'Copy' : 'Add'} to a playlist`}<br /><span className='help restriction'>A maximum of 50 tracks are permitted per playlist.</span></p>
          <button className='delete' aria-label='close' onClick={() => setActive({ show: false, trackId: null, playlistId: null })} />
        </header>
        <section className='modal-card-body playlist-selector'>
          <span className={classNames('help is-danger', { 'is-hidden': noPlaylistError })}>Please select a playlist</span>
          <div className='playlist-selector__list-wrapper'>
            <Paginator>
              {playlists}
            </Paginator>
          </div>
        </section>
        <footer className='modal-card-foot'>
          <Button onClick={() => setActive({ show: false, trackId: null, playlistId: null })} classes={['modal-cancel']}>
            Cancel
          </Button>
          &nbsp;
          <Button classes='submitButton' onClick={handleAddToPlaylist}>
            <span>{mode === 'copy' ? 'Copy' : 'Add'}</span>
            <FontAwesomeIcon icon={faAngleRight} size='2x' />
          </Button>
        </footer>
      </div>
    </div>
  )
}

export default AddToPlaylist
