const initialState = {
  station: 0,
  week: 0,
  method: null,
  playbacks: [],
  isAddingAirtimes: false,
  isReviewing: false,
  isSubmitting: false,
  isSubmittingSuccessful: false
}

const affidavitsReducer = (state = initialState, action = {}) => {
  const type = action.type

  if (type === 'affidavits.setStation') return { ...state, station: action.station }
  if (type === 'affidavits.clearStation') return { ...state, station: 0 }

  if (type === 'affidavits.setWeek') return { ...state, week: action.week }
  if (type === 'affidavits.clearWeek') return { ...state, week: 0 }

  if (type === 'affidavits.setMethod') return { ...state, method: action.method }
  if (type === 'affidavits.clearMethod') return { ...state, method: null }

  if (type === 'affidavits.setPlaybacks') return { ...state, playbacks: typeof action.playbacks === 'function' ? action.playbacks(state.playbacks) : action.playbacks }
  if (type === 'affidavits.clearPlaybacks') return { ...state, playbacks: [] }

  if (type === 'affidavits.enterAddingAirtimes') return { ...state, isAddingAirtimes: true }
  if (type === 'affidavits.exitAddingAirtimes') return { ...state, isAddingAirtimes: false }

  if (type === 'affidavits.enterReviewing') return { ...state, isReviewing: true }
  if (type === 'affidavits.exitReviewing') return { ...state, isReviewing: false }

  if (type === 'affidavits.enterSubmitting') return { ...state, isSubmitting: true }
  if (type === 'affidavits.exitSubmitting') return { ...state, isSubmitting: false }

  if (type === 'affidavits.submissionSuccess') return { ...state, isSubmittingSuccessful: true }

  if (type === 'affidavits.reset') return initialState

  return state
}

export default affidavitsReducer
