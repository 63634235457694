import React from 'react'
import { Helmet } from 'react-helmet'

import MainLayout from '../containers/MainLayout'
import AffidavitsSystem from '../components/AffidavitsSystem'

const AffidavitsPage = () => {
  return (
    <MainLayout fullheight>
      <Helmet>
        <title>Affidavits - TAA Music</title>
      </Helmet>
      <div className='container'>
        <AffidavitsSystem />
      </div>
    </MainLayout>
  )
}

export default AffidavitsPage
