import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faSlidersH } from '@fortawesome/pro-light-svg-icons'
import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons'

import createAction from '../../../action'

import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'

import useModal from '../../useModal'

const Player = props => {
  const { mobileGenreSwitch, mobileGenreState } = props
  const state = useSelector(state => ({ songUrl: state.playerNFilters.songUrl, songTitle: state.playerNFilters.songTitle, albumArt: state.playerNFilters.albumArt }))
  const dispatch = useDispatch()
  const [modal, showModal] = useModal()
  const player = useRef()

  useEffect(() => () => dispatch(createAction('playerNFilters.playSongReset')), []) // eslint-disable-line

  const notifyUser = async e => {
    player.current.audio.current.pause()
    dispatch(createAction('playerNFilters.playSongReset'))
    await showModal(
      'Playback Error',
      <>
        <p>An error occurred while attempting to play the selected track. This could be due to internet connectivity issues or that the selected track is unavailable.</p><br />
        <small className='is-size-7'>Error: {e.target.error.code}{e.target.error.message ? `, ${e.target.error.message}` : ''}</small>
      </>,
      [{
        text: 'OK',
        className: 'submitButton',
        response: 'ok',
        icon: ['fal', 'angle-right'],
        iconProps: {
          size: '2x'
        }
      }]
    )
  }

  return (
    <div className='explore__player-wrapper columns is-multiline'>
      <div className='explore__player-albumart column is-full'>
        <div className='explore__player-albumart--inner-wrapper'>{state.albumArt && <img src={state.albumArt} alt='' />}</div>
      </div>
      <AudioPlayer
        autoPlay
        src={state.songUrl ? state.songUrl : null}
        showDownloadProgress={false}
        customProgressBarSection={[
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.CURRENT_TIME,
          RHAP_UI.DURATION
        ]}
        ref={player}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        showFilledVolume={false}
        showSkipControls
        customIcons={{
          play: <FontAwesomeIcon icon={faPlay} />,
          rewind: <FontAwesomeIcon icon={faAngleDoubleLeft} />,
          forward: <FontAwesomeIcon icon={faAngleDoubleRight} />,
          pause: <FontAwesomeIcon icon={faPause} />
        }}
        layout='stacked-reverse'
        progressJumpSteps={{ backward: 3000, forward: 3000 }}
        onClickNext={() => dispatch(createAction('playerNFilters.playNext'))}
        onClickPrevious={() => dispatch(createAction('playerNFilters.playPrevious'))}
        onError={e => notifyUser(e)}
      />
      <div className='explore__player-track-title column is-full'>
        <p>
          {state.songTitle}
          <button type='button' onClick={() => mobileGenreSwitch(!mobileGenreState)} className='explore__player-track-title__mobile-filters-button'><FontAwesomeIcon icon={faSlidersH} /></button>
        </p>
      </div>
      {modal}
    </div>
  )
}

export default Player
