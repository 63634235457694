import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import createAction from '../../action'
import { useFrankentangel } from '../../frankentangel'
import secondsConverter from '../../utils/secondsConverter'
import useModal from '../useModal'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import useDownloadDisclaimer from './useDownloadDisclaimer'
import Button from '../Button'

const Track = props => {
  const { trackId, albumArt, title, albumName, libraryName, duration, addToList, remove, copy, genres, tempo, userBookmarks, trackIndex, isActiveUser, playlist, userContract } = props
  const dispatch = useDispatch()
  const frankentangel = useFrankentangel()
  const state = useSelector(state => ({ trackIndex: state.playerNFilters.trackIndex }))
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const history = useHistory()
  const [disclaimerModal, showDisclaimer] = useDownloadDisclaimer()

  const [modal, showModal] = useModal()

  const isBookmarked = userBookmarks && userBookmarks[0] && userBookmarks[0].songs.map(song => song.id).includes(trackId)

  useEffect(() => {
    if (state.trackIndex === trackIndex) loadSong()
  }, [state.trackIndex]) // eslint-disable-line

  const renderOnSize = () => setWindowWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', renderOnSize)

    return () => window.removeEventListener('resize', renderOnSize)
  }, []) // eslint-disable-line

  const loadSong = async () => {
    dispatch(createAction('playerNFilters.playSong', { songUrl: null, songTitle: title, albumArt: albumArt, songGenres: genres, songTempo: tempo, trackIndex: trackIndex }))
    const result = await frankentangel.viewDocument('song', trackId, 'playbackUrl')
    dispatch(createAction('playerNFilters.playSong', { songUrl: result, songTitle: title, albumArt: albumArt, songGenres: genres, songTempo: tempo, trackIndex: trackIndex }))
  }

  const action = () => {
    dispatch(createAction('playerNFilters.trackIndex', { trackIndex: trackIndex }))
  }

  const [isBookmarking, setBookmarking] = useState(false)

  const bookmarkTrack = async () => {
    setBookmarking(true)

    if (isActiveUser && !isBookmarked) await frankentangel.runAction('playlists', 'addToBookmarks', { song: trackId })
    else if (isActiveUser && isBookmarked) await frankentangel.updateDocument('playlists', userBookmarks[0].id, { songs: userBookmarks[0].songs.map(song => song.id).filter(id => id !== trackId).join(', ') })

    setBookmarking(false)
  }

  const askToLogin = async () => {
    const response = await showModal(
      'Please log in',
      'To use specific features on this site, please log in',
      [{
        text: 'Cancel',
        response: 'cancel',
        isCancel: true,
        className: 'modal-cancel'
      },
      {
        text: 'Log In',
        response: 'yes',
        className: 'submitButton',
        icon: ['fal', 'angle-right'],
        iconProps: {
          size: '2x'
        }
      }]
    )

    if (response !== 'yes') return
    history.push('/login')
  }

  const downloadDisclaimer = async () => {
    if (await showDisclaimer()) await downloadSong()
  }

  const [downloadingSong, setDownloadingSong] = useState(false)

  const downloadSong = async () => {
    setDownloadingSong(true)

    try {
      const downloadUrl = await frankentangel.runAction('song', 'getDownloadUrl', { id: trackId, contract: userContract })

      if (!downloadUrl['320_check']) throw new Error(`Something went wrong with the download URL: ${downloadUrl.error}`)

      const downloadResult = await window.fetch(downloadUrl['320'])
      const commercial = Buffer.from(await downloadResult.arrayBuffer())

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new window.Blob([commercial]))
      link.download = `${title}-${libraryName}-${albumName}.mp3`
      link.click()
    } catch (err) {
      setDownloadingSong(false)
      throw err
    }

    setDownloadingSong(false)
  }

  const removeFromPlaylist = async () => {
    console.log('Remove')
    const modifiedSongs = playlist.doc.songs.map(song => song.id).filter(id => id !== trackId).join(', ')
    await frankentangel.updateDocument('playlists', playlist.doc.id, { songs: modifiedSongs })
  }

  const propagationGuard = (event) => event.stopPropagation()

  if (windowWidth <= 768) {
    return (
      <div className={classNames('explore__track-wrapper', 'explore__track-wrapper--mobile', { 'is-active': state.trackIndex === trackIndex })} onClick={propagationGuard}>
        <div className='explore__track-art-title explore__track-art-title--mobile' onClick={action}>
          <div>
            <div className='explore__mobile-album-art-wrapper'>
              <img src={albumArt} alt='' />
            </div>
            <div className='explore__mobile-titles-wrapper'>
              <span>{(title.length >= 20) && (windowWidth < 600) ? title.substring(0, 17) + '...' : title}</span><br />
              <span>{(albumName.length >= 20) && (windowWidth < 600) ? albumName.substring(0, 17) + '...' : albumName}</span><br />
              <span className='is-italic'>{(libraryName.length >= 20) && (windowWidth < 600) ? libraryName.substring(0, 17) + '...' : libraryName}</span>
            </div>
          </div>
        </div>
        <div className='mobile explore__mobile-duration-wrapper' onClick={action}>
          <span>{secondsConverter(duration)}</span>
        </div>
        <div className='explore__track-controls explore__track-controls--mobile'>
          {userBookmarks && <button type='button' onClick={isActiveUser ? bookmarkTrack : askToLogin}><FontAwesomeIcon icon={isBookmarking ? ['fal', 'sync-alt'] : (isBookmarked ? faStar : ['fal', 'star'])} spin={isBookmarking} /></button>}
          {addToList && <button type='button' onClick={isActiveUser ? addToList : askToLogin}><FontAwesomeIcon icon={faPlus} /></button>}
          {remove && <button type='button' onClick={isActiveUser ? removeFromPlaylist : askToLogin}><FontAwesomeIcon icon={['fal', 'trash-alt']} /></button>}
          {copy && <button type='button' onClick={isActiveUser ? copy : askToLogin}><FontAwesomeIcon icon={['fal', 'copy']} /></button>}
        </div>
        {modal}
        {disclaimerModal}
      </div>
    )
  }

  return (
    <div className={classNames('explore__track-wrapper', 'columns', 'is-vcentered', 'mb-5', { 'is-active': state.trackIndex === trackIndex })}>
      <div className='column is-4 explore__track-art-title' onClick={action}>
        <div className='explore__track-art-title--inner-wrapper'><img src={albumArt} alt='' /></div>&nbsp;<span>{title}</span>
      </div>
      <div className='column is-5 explore__track-album' onClick={action}>
        <div className='columns is-multiline'>
          <div className='column is-full is-paddingless'>
            <span>{albumName}</span>
          </div>
          <div className='column is-11 is-paddingless'>
            <span className='is-italic'>{libraryName}</span>
          </div>
          <div className='column is-1 is-paddingless'>
            <span>{secondsConverter(duration)}</span>
          </div>
        </div>
      </div>
      <div className='column is-3 explore__track-controls'>
        {userBookmarks && <Button noClass type='button' onClick={isActiveUser ? bookmarkTrack : askToLogin}><FontAwesomeIcon icon={isBookmarking ? ['fal', 'sync-alt'] : (isBookmarked ? faStar : ['fal', 'star'])} spin={isBookmarking} /></Button>}
        <Button noClass onClick={isActiveUser ? downloadDisclaimer : askToLogin}><FontAwesomeIcon icon={downloadingSong ? ['fal', 'sync-alt'] : ['fal', 'cloud-download']} spin={downloadingSong} /></Button>
        {addToList && <Button noClass type='button' onClick={isActiveUser ? addToList : askToLogin}><FontAwesomeIcon icon={faPlus} /></Button>}
        {remove && <Button noClass type='button' onClick={isActiveUser ? removeFromPlaylist : askToLogin}><FontAwesomeIcon icon={['fal', 'trash-alt']} /></Button>}
        {copy && <Button noClass type='button' onClick={isActiveUser ? copy : askToLogin}><FontAwesomeIcon icon={['fal', 'copy']} /></Button>}
      </div>
      {modal}
      {disclaimerModal}
    </div>
  )
}

export default Track
