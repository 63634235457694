import React from 'react'
import moment from 'moment'

import useModal from '../useModal'

const useDownloadDisclaimer = () => {
  const [modal, showModal] = useModal()

  const showDisclaimer = async () => {
    const didUserConsent = window.localStorage.getItem('taaDownloadConsent')
    let shouldAgree = false
    if (didUserConsent) {
      const today = moment(new Date())
      const consentDate = moment(didUserConsent)
      if (today.diff(consentDate, 'hours') >= 24) shouldAgree = true
    }
    if (!didUserConsent) shouldAgree = true

    if (!shouldAgree) return true

    const response = await showModal(
      'Download Agreement',
      (
        <div className='content'>
          <p className='downloadSubtitle'>Terms and Conditions of Use of TAA Music Station Imaging & Commercial Library Service Copyrighted Materials:</p>
          <p className='downloadSubhead'>Responsibility of User ("Licensee"):</p>
          <ul>
            <li>The Licensee shall use the "Station Imaging & Commercial Library Service" solely for production purposes of the stations licensed to use our Library Service identified in Licensee agreement on file at TAA Music, Inc.</li>
            <li>Licensee shall be solely responsible for maintaining any necessary public performance license with ASCAP, and report any music or performance thereof related to the "TAA Music". Usage must be reported as "TAA MUSIC".</li>
            <li>Licensee shall be solely responsible for complying with all licenses, restrictions and any other agreement and covenants related to  the use of the "TAA Music Imaging & Commercial  Library Service".</li>
            <li>Licensee shall not use or permit others to use the "TAA Music Station Imaging & Commercial Library Service", its name or any otherwise proprietary information associated with the  "Station Imaging & Commercial Library  Service" in violation of any  copyright, trademark or other intellectual property right related to the "TAA Music Station Imaging & Commercial Library  Service."</li>
            <li>Licensee shall not authorize, cause or permit or enable any person or entity to record, duplicate, broadcast or transmit in any way  all or a portion of the music, sounds, and effects provided by the "TAA Music Station Imaging & Commercial Library Service", except permitted through Licensing Agreement;</li>
            <li>Licensee shall indemnify, defend and hold TAA Music harmless  from and against any claims, proceedings, suits, actions, or costs of  any kind or nature incurred by TAA Music on account of a breach by any of its covenants or agreements set forth in any material provision of this Agreement.</li>
            <li>Upon the expiration of License agreement, station shall return all CD's or other materials in the libraries that were licensed to Licensee and owned by TAA Music, at stations expense, and take all  music from those listed libraries off the air.</li>
          </ul>
        </div>
      ),
      [{
        text: 'Cancel',
        response: 'cancel',
        isCancel: true,
        className: 'modal-cancel'
      },
      {
        text: 'I Agree',
        response: 'yes',
        className: 'submitButton',
        icon: ['fal', 'angle-right'],
        iconProps: {
          size: '2x'
        }
      }]
    )

    if (response !== 'yes') return false

    window.localStorage.setItem('taaDownloadConsent', new Date())
    return true
  }

  return [modal, showDisclaimer]
}

export default useDownloadDisclaimer
