import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import MainLayout from '../containers/MainLayout'
import Gradients from '../components/Home/Gradients'
import BackToTopSideButton from '../components/BackToTopSideButton'
import Search from '../components/Explore/Search/Search'
import Playlists from '../components/Explore/Playlists/Playlists'
import Player from '../components/Explore/Player/Player'
import Filters from '../components/Explore/Filters/Filters'
import { Tabs, Tab } from '../components/Tabs'
import { useSession, useAction, useFrankentangel } from '../frankentangel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'

const Explore = props => {
  const frankentangel = useFrankentangel()
  const [currentSession] = useSession()
  const [showGenres, setShowGenres] = useState(false)
  const [contractId, setContractId] = useState(0)
  const currentUser = (currentSession || {}).user ? true : undefined
  const currentUserId = (currentSession || {}).user ? (currentSession || {}).user : null
  const history = useHistory()
  const bookmarks = useAction('playlists', 'getBookmarks')

  const getContractWithHighestDownloadCount = async () => {
    const { session } = await frankentangel.viewSession()
    if (!session.user) return
    const fetchedUser = await frankentangel.viewDocument('users', session.user)
    if (fetchedUser.station.length === 0) return
    const sortedContracts = fetchedUser.station.map(oneStation => ({ id: oneStation.contract?.id, downloadsLeft: oneStation.contract?.downloadsLeft })).filter(a => a.downloadsLeft).sort((a, b) => b.downloadsLeft - a.downloadsLeft)
    setContractId(sortedContracts[0].id)
  }

  useEffect(() => {
    getContractWithHighestDownloadCount()
  }, []) // eslint-disable-line

  return (
    <MainLayout alignstart fullheight innerClasses={['home-body', 'explore-body']}>
      <Helmet>
        <title>Explore - TAA Music</title>
      </Helmet>
      <Gradients />
      <BackToTopSideButton watchTargetSelector='.is-6' />
      <div className='explore-page'>
        <div className='columns'>
          <div className='column is-5 player-filters'>
            <Player mobileGenreSwitch={setShowGenres} mobileGenreState={showGenres} />
            <Filters mobileGenreSwitch={setShowGenres} mobileGenreState={showGenres} />
          </div>
          <div className='column is-6 is-offset-1 is-7-desktop is-7-tablet search-playlists'>
            <Tabs
              mode=''
              headers={['Search', 'Playlists']}
            >
              <Tab>
                <Search currentUser={currentUser} userBookmarks={bookmarks} userContract={contractId} />
              </Tab>
              <Tab>
                {!currentUser && (
                  <>
                    <p style={{ width: '100%', marginBottom: '1rem' }}>Please login to manage your playlists here</p>
                    <button type='button' onClick={() => history.push('/login')} className='submitButton'>
                      <span>Login</span>
                      <FontAwesomeIcon icon={faAngleRight} size='2x' />
                    </button>
                  </>
                )}
                {currentUser && <Playlists currentUser={currentUser} currentUserId={currentUserId} userContract={contractId} />}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Explore
