import React, { useState } from 'react'
import classNames from 'classnames'
import useModal from './useModal'
import unwrap from 'async-unwrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = props => {
  const { id, disabled, children, classes, type, onClick, icon, noClass, ...rest } = props

  const [isLoading, setLoading] = useState(false)

  const [modal, showModal] = useModal()

  const handleOnClick = async (ev) => {
    setLoading(true)
    const [error] = await Promise.resolve(onClick(ev))[unwrap]
    if (error) {
      const errorMsg = error ? (error.message || error) : 'Unknown Error'
      await showModal('Error', errorMsg, [{ text: 'OK', className: 'is-danger', response: 'OK' }])
    }
    setLoading(false)
  }

  const buttonProperties = {
    ...rest,
    type: type || 'button',
    disabled: disabled || isLoading,
    className: classNames({ button: !noClass, 'is-loading': isLoading }, classes),
    id,
    onClick: onClick ? handleOnClick : null
  }

  return (
    <>
      <button {...buttonProperties}>
        {icon && (<span className='icon'><FontAwesomeIcon icon={icon} /></span>)}
        {children && <span>{children}</span>}
      </button>
      <div onClick={ev => ev.stopPropagation()} style={{ position: 'fixed', width: 0, height: 0, top: '-10px', left: '-10px' }}>
        {modal}
      </div>
    </>
  )
}

export default Button
