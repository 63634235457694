import React from 'react'
import { Link } from 'react-router-dom'

const Footer = props => {
  const goUp = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  return (
    <div className='hero-foot'>
      <footer className='footer'>
        <button onClick={goUp} className='footer__btn-btt has-text-purple is-uppercase is-slightly-bigger is-family-secondary' type='button'>Back to top</button>
        <nav className='footer-nav'>
          <ul>
            <li>
              <Link to='/contact'>Contact Us</Link>
            </li>
            <li>
              <Link to='/terms-of-service'>Terms of Service</Link>
            </li>
            <li>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </li>
          </ul>
        </nav>
      </footer>
    </div>
  )
}

export default Footer
