import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSession, useFrankentangel } from '../frankentangel'
import classNames from 'classnames'

const DesktopNavbar = props => {
  const [currentSession] = useSession()
  const frankentangel = useFrankentangel()
  const { currentTheme } = props
  const [burgerOpen, setBurgerOpen] = useState(false)
  const [showCommsAff, setshowCommsAff] = useState(true)
  const [dropDownStates, setDropDownStates] = useState({
    profile: false
  })

  const dropDownSwitcher = (ev, which) => {
    if (!ev.target.href || new URL(ev.target.href, window.location.href).host === window.location.host) ev.preventDefault()
    const currentState = dropDownStates
    currentState[which] = !dropDownStates[which]
    setDropDownStates({ ...currentState })
  }

  const themeSwitcherText = currentTheme === 'lite-theme' ? 'Dark Mode' : 'Lite Mode'

  const logOut = () => frankentangel.runAction('users', 'logout')

  const currentUser = (currentSession || {}).user ? true : undefined

  const closeAccountDropdown = ev => {
    if (dropDownStates.profile) dropDownSwitcher(ev, 'profile')
  }

  const checkCashUser = async () => {
    const { session } = await frankentangel.viewSession()
    if (!session.user) return
    const fetchedUser = await frankentangel.viewDocument('users', session.user)
    if (fetchedUser.station.length === 0) return
    const notCashTypes = fetchedUser.station.filter(oneStation => oneStation.contract?.contractType === 'Barter' || oneStation.contract?.contractType === 'Barter Settlement')
    if (notCashTypes.length === 0) setshowCommsAff(false)
  }

  useEffect(() => {
    checkCashUser()
  }, []) // eslint-disable-line

  return (
    <nav className={classNames('navbar', 'px-6', 'is-family-secondary', currentTheme)} role='navigation' aria-label='main navigation'>
      <div className={classNames('navbar-brand', { 'is-active': burgerOpen })}>
        <Link to='/' className='navbar-item' tabIndex={-1}>
          <img src='https://cdn.taamusic.com/taa-logo-color-300.png' alt='TAA Logo' />
        </Link>
        <p role='button' className={classNames('navbar-burger', 'burger', { 'is-active': burgerOpen })} aria-label='menu' aria-expanded='false' data-target='navMenu' onClick={() => setBurgerOpen(!burgerOpen)}>
          <span aria-hidden='true' />
          <span aria-hidden='true' />
          <span aria-hidden='true' />
        </p>
      </div>

      <div className={classNames('navbar-menu', { 'is-active': burgerOpen })}>
        <div className='navbar-start is-slightly-bigger is-uppercase' onClick={ev => closeAccountDropdown(ev)}>
          <Link to='/explore' className='navbar-item has-text-teal'>Explore</Link>
          <Link to='/about' className='navbar-item has-text-teal'>About</Link>
          <Link to='/contact' className='navbar-item has-text-teal'>Contact</Link>
          <div className='navbar-item theme-switcher__outer-wrapper'>
            <div className='theme-switcher is-flex is-align-items-center' onClick={props.handleSwitchTheme}>
              <div className='theme-switcher__slider'>
                <div className={classNames('theme-switcher__ball', { 'dark-mode': currentTheme !== 'lite-theme' })} />
              </div>
              <div className='theme-switcher__text is-uppercase is-slightly-bigger'>
                {themeSwitcherText}
              </div>
            </div>
          </div>
        </div>
        <div className='navbar-end is-slightly-bigger is-uppercase'>
          {currentUser &&
            <div className={classNames('navbar-item', 'has-dropdown', 'profile-dropdown', { 'is-active': dropDownStates.profile })} onClick={ev => dropDownSwitcher(ev, 'profile')}>
              <a href='/' className='navbar-link is-arrowless'>
                Account
              </a>

              <div className='navbar-dropdown'>
                {showCommsAff && (
                  <>
                    <Link to='/commercials' className='navbar-item'>
                      Commercials
                    </Link>
                    <Link to='/affidavits' className='navbar-item'>
                      Affidavits
                    </Link>
                  </>
                )}
                <Link to='/settings' className='navbar-item'>
                  Settings
                </Link>
                <Link to='/' onClick={logOut} className='navbar-item'>Log out</Link>
              </div>
            </div>}
          {!currentUser && <Link to='/login' className='navbar-item has-text-teal'>Log in</Link>}
        </div>
      </div>
    </nav>
  )
}

export default DesktopNavbar
