import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import createAction from '../../../action'

const Filter = props => {
  const { title, status, techTitle } = props
  const [currentState, setCurrentState] = useState(status)
  const state = useSelector(state => ({ songSearch: state.playerNFilters.songSearch }))
  const dispatch = useDispatch()

  const handleFilter = () => {
    const newState = currentState === 'base' ? 'add' : (currentState === 'remove' ? 'base' : 'remove')
    setCurrentState(prevState => newState)
    let newSearchStr
    switch (true) {
      case newState === 'base':
        newSearchStr = state.songSearch.replace((state.songSearch.includes(` -${techTitle}`) ? ` -${techTitle}` : `-${techTitle}`), '')
        newSearchStr = newSearchStr.replace((state.songSearch.includes(` ${techTitle}`) ? ` ${techTitle}` : techTitle), '')
        break
      case newState === 'add':
        newSearchStr = state.songSearch.length > 0 ? (state.songSearch + ` ${techTitle}`) : techTitle
        break
      default:
        newSearchStr = state.songSearch.replace((state.songSearch.includes(` ${techTitle}`) ? ` ${techTitle}` : `${techTitle}`), (state.songSearch.length > 0 ? ` -${techTitle}` : `-${techTitle}`))
    }
    dispatch(createAction('playerNFilters.songSearch', { songSearch: newSearchStr }))
  }

  return (
    <div className='filter column is-4-desktop is-6-tablet is-12-mobile'>
      <div onClick={handleFilter} className={classNames('filter-item', { 'is-add': currentState === 'add' }, { 'is-remove': currentState === 'remove' })}>
        <div className='filter-item__title'>{title}</div>
        <div className='filter-item__status'>
          {currentState === 'remove' && <FontAwesomeIcon icon={faTimes} />}
          {currentState === 'add' && <FontAwesomeIcon icon={faCheck} />}
        </div>
      </div>
    </div>
  )
}

export default Filter
