import { faCheckCircle, faExclamationCircle, faSyncAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { useDocument, useList, useSession } from '../frankentangel'
import StationSelector from './StationSelector'
import WeekSelector from './WeekSelector'
// import xkcd1179withTZ from '../utils/xkcd1179-with-tz'
// import WipBanner from './WipBanner'
import AffidavitMethodSelector from './AffidavitMethodSelector'
import AffidavitsSubmissionSystem from './AffidavitsSubmissionSystem'

const AffidavitsSystem = () => {
  const selectedStation = useSelector(state => state.affidavits.station)
  const selectedWeek = useSelector(state => state.affidavits.week)
  const selectedMethod = useSelector(state => state.affidavits.method)

  const [session, , sessionLoading] = useSession()
  const [user, , userLoading] = useDocument('users', session && session.user)
  const [station, , stationLoading] = useDocument('accounts', selectedStation)
  const [WeekPaginator, weeks, , weeksLoading] = useList('commercial-weeks', 'listWithStatus', { accountId: selectedStation })
  const week = weeks?.filter(week => week.id === selectedWeek).map(week => week.doc)[0]

  const weekDate = new Date(week?.actualDate.replace(/-/g, '/'))
  const weekOfString = `Week of ${weekDate.toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })}`

  // if (weeksError) console.error('error with week', weeksError)

  const isLoading = sessionLoading || userLoading || stationLoading || weeksLoading

  return (
    <div className='affidavits-system'>
      {/* <WipBanner /> */}
      {
        isLoading
          ? (
            <p className='affidavits-system__loading has-text-centered'>
              <FontAwesomeIcon icon={faSyncAlt} spin size='6x' />
            </p>
            )
          : (
              !station
                ? <StationSelector
                    title='Affidavits'
                    user={user}
                    setAction='affidavits.setStation'
                    notice={doc => (
                      !doc?.affidavitsSubmitted
                        ? (
                          <div className='station-selector__station-notice'>
                            <span className='icon'>
                              <FontAwesomeIcon icon={faExclamationCircle} />
                            </span>
                            <span>
                              Action Required
                            </span>
                          </div>
                          )
                        : undefined
                    )}
                  />
                : (
                    !week
                      ? (
                        <WeekPaginator>
                          <WeekSelector
                            title={`Affidavits for ${station.callsign}`}
                            user={user}
                            setAction='affidavits.setWeek'
                            backAction='affidavits.clearStation'
                            system='affidavits'
                            notice={doc => (
                              !doc.affidavitsSubmitted
                                ? (
                                  <div className='week-selector__week-notice'>
                                    <span className='icon'>
                                      <FontAwesomeIcon icon={faExclamationCircle} />
                                    </span>
                                    <span>
                                      Action Required
                                    </span>
                                  </div>
                                  )
                                : ({
                                    _ghostNotice: true,
                                    content: (
                                      <div className='week-selector__week-notice'>
                                        <span className='icon'>
                                          <FontAwesomeIcon icon={faCheckCircle} />
                                        </span>
                                        <span>
                                          Complete
                                        </span>
                                      </div>
                                    )
                                  })
                            )}
                            pastOnly
                          />
                        </WeekPaginator>
                        )
                      : (
                          week.affidavitsSubmitted
                            ? <AffidavitsSubmissionSystem readOnly />
                            : (
                                !selectedMethod
                                  ? <AffidavitMethodSelector title={`Affidavit for ${station?.callsign}, ${weekOfString}`} />
                                  : <AffidavitsSubmissionSystem />
                              )
                        )
                  )
            )
      }
    </div>
  )
}

export default AffidavitsSystem
