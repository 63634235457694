import React from 'react'
import MainLayout from '../containers/MainLayout'
import { Helmet } from 'react-helmet'

const PrivacyPolicy = props => {
  return (
    <MainLayout fullheight>
      <Helmet>
        <title>Privacy Policy - TAA Music</title>
      </Helmet>
      <div className='container'>
        <div className='columns'>
          <div className='privacy-page column is-full content'>
            <h1>TAA Music Privacy Policy</h1>
            <h3>Introduction</h3>
            <p>TAA Music, Inc (herein referred to as "TAA"), takes data privacy very seriously.  This Privacy Policy is intended to inform you how TAA gathers, uses, discloses and manages your personal information in connection with your use of our website and digital services. This website uses a mobile-responsive design which allows it to optimize its functionality for desktop computers, tablets and mobile phones that can access the internet through popular browsers, therefore, this privacy policy applies to this website regardless of the device by which it is accessed.</p>
            <p><strong>TAA does not engage in the sharing, trading, selling or distribution of any personal information whatsoever.  Any personal information collected by TAA is for the sole purpose of conducting business and providing digital services to our users, subscribers and account holders. We value our customers and have taken important steps to safeguard and use personal data responsibly.</strong></p>
            <h3>Consent</h3>
            <p><strong>By visiting, utilizing and conducting business on this website you agree to be bound by these Terms of Service as stated herein. Your use of this website also constitutes your acceptance and agreement to our Privacy Policy.</strong></p>
            <h3>Information Collected by This Website</h3>
            <p>TAA collects information you provide directly via this website. This  information can be used to identify you or can be reasonably linked, directly or indirectly to you. At no point in the data collection process does this website collect Personally Identifiable Information without your consent. You indicate consent by submitting your information or by agreeing to continue with a given transaction.</p>
            <p><strong>Personal data collected through this website is limited:</strong></p>
            <ul>
              <li>Contact Information: We collect your first and last name, email address, company / call letters and telephone number for the purposes of account identification.</li>
              <li>Message: You can decide how much information to include in your message when using our contact form. We ask that your provide enough context to allow us to properly respond to your inquiry.</li>
            </ul>
            <p><strong>Air Time Compliance Affidavits:</strong></p>
            <ul>
              <li>Commercial Air Time: We collect specific data regarding advertising by your station, this information is confidential and governed by the contract on file at TAA.</li>
            </ul>
            <p><strong>Explore Feature (Music Search):</strong></p>
            <ul>
              <li>Search: This website utilizes an advanced search feature which accepts non-personally identifiable information to assist in locating music from our catalog.</li>
              <li>Playlists: This website features playlist functionality for saving, categorizing and organizing specific tracks from our catalog. Users may provide custom names for their playlists.</li>
            </ul>
            <h3>Cookies</h3>
            <ul>
              <li><strong>Metrics: </strong>This website uses Google Analytics and therefore uses cookies to collect anonymous data about website use.  We use this data to improve  website functionality and deliver an optimized user experience. This information may also be used to inform decision making for marketing and  advertising.</li>
              <li><strong>Website State: </strong>We use cookies to retain the state of the website, for example, your preference for lite/dark mode. Cookies are solely used  to support website functionality.</li>
            </ul>
            <h3>Disclosure of Your Information</h3>
            <p>TAA fully owns this website and retains it's control.  TAA has worked with a trusted third-party in the development of this website.</p>
            <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety.</p>
            <h3>Usage of Information</h3>
            <p>Any of the information we collect from you will be used solely to process a transaction in the process of conducting business.  This website only collects the information necessary for the transaction being performed and to fulfill the service being requested by the user.</p>
            <h3>Safeguards and Security</h3>
            <p>TAA maintains reasonable internal controls and  procedures to ensure  that the information you share with us is handled in a safe, secure and  responsible manner. Our security measures help protect against unauthorized access and alteration of the information in our control. We will only disclose such information to employees and authorized service providers who require such information for the purposes of providing a product or service and only if necessary.</p>
            <p>We use industry standard e-commerce security technology such as Transport Layer Security (TLS) encryption technology. We use TLS technology to  prevent sensitive information from being  intercepted and read as it is transmitted over the Internet. We also use "at-rest" encryption for data that is stored in our databases. This website does not store credit card or any other type of financial information.</p>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default PrivacyPolicy
