import React from 'react'
import { useFrankentangel } from '../frankentangel'
import useModal from './useModal'

const useObjectDelete = () => {
  const frankentangel = useFrankentangel()
  const [modal, showModal] = useModal()

  const handleDelete = async (objectName, tableName, objectId, objectTitle) => {
    const response = await showModal(
      'Delete Confirmation',
      `Are you sure you'd like to delete ${objectName} ${objectTitle}?`,
      [{
        text: 'No',
        response: 'no',
        className: 'modal-cancel',
        isCancel: true
      }, {
        text: `Yes, delete ${objectName}`,
        className: 'submitButton',
        response: 'yes',
        icon: ['fal', 'angle-right'],
        iconProps: {
          size: '2x'
        }
      }]
    )
    if (response !== 'yes') return
    await frankentangel.deleteDocument(tableName, objectId)
  }

  const deleteModal = (
    <>
      {modal}
    </>
  )

  return [deleteModal, handleDelete]
}

export default useObjectDelete
