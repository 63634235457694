import React from 'react'
import MainLayout from '../containers/MainLayout'
import FormWrapper from '../components/FormWrapper'
import FormStatus from '../components/FormStatus'
import FormInputField from '../components/FormInputField'
import SubmitButton from '../components/SubmitButton'
import { useFrankentangel } from '../frankentangel'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'

const PasswordResetRequest = props => {
  const frankentangel = useFrankentangel()

  const history = useHistory()

  const submitResetFunction = async values => {
    await frankentangel.runAction('users', 'requestReset', { ...values })
    return 'Request submitted successfully. Please check your email for further instructions.'
  }

  return (
    <MainLayout fullheight centered>
      <Helmet>
        <title>Reset Password - TAA Music</title>
      </Helmet>
      <div className='container has-text-centered'>
        <div className='columns is-centered'>
          <div className='column is-one-third'>
            <div className='box has-text-left has-background-purple login-page'>
              <h1 className='title has-text-purple has-text-weight-light'>Request password reset</h1>
              <FormWrapper
                fields={yup.object({
                  email: yup.string('Letters only').required('Required').email('Please provide a valid email address')
                })}
                submit={submitResetFunction}
              >
                <FormInputField name='email' type='email' label='EMAIL ADDRESS' />
                <SubmitButton buttonText='Request Reset' />
                <FormStatus />
              </FormWrapper>
              <div className='block has-text-right'>
                <p>
                  <a onClick={() => history.goBack()}>BACK</a> {/* eslint-disable-line */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default PasswordResetRequest
