import React from 'react'

import Track from './Track'
import TrackLoading from './TrackLoading'

import { useDocument } from '../../frankentangel'

const AsyncTrack = (props) => {
  const { id, index, currentUser, playlist, copyToPlaylist, userContract } = props

  const [song, error, loading] = useDocument('song', id)

  if (loading) return <TrackLoading />
  if (error) {
    console.error(`Error while loading track ${id}:`, error)
    return null
  }

  return (
    <Track
      key={song.id}
      trackId={song.id}
      trackIndex={index}
      title={song.mix ? `${song.song_title} (${song.mix})` : song.song_title}
      albumArt={`https://cdn.taamusic.com/album-art/${song.album?.album_name}.png`}
      albumName={song.album?.album_name}
      libraryName={song.album?.library?.library_name}
      duration={song.song_length}
      genres={song.genre.map(gen => ({ title: gen.genre.replace('/', ' / '), searchTerm: gen.technical_name }))}
      tempo={song.tempo}
      isActiveUser={!!currentUser}
      playlist={playlist}
      remove
      copy={() => copyToPlaylist(song.id)}
      userContract={userContract}
    />
  )
}

export default AsyncTrack
