import React, { useEffect } from 'react'
import classNames from 'classnames'
import DesktopNavbar from '../components/DesktopNavbar'
import Footer from '../components/Footer'
import useGlobalState from '../components/useGlobalState'

const MainLayout = props => {
  const [selectedTheme, setTheme] = useGlobalState('theme', '')
  const storage = window.localStorage

  const switchTheme = () => {
    const themeToSet = selectedTheme === 'lite-theme' ? 'dark-theme' : 'lite-theme'
    storage.setItem('theme', themeToSet)
    setTheme(themeToSet)
  }

  useEffect(() => {
    if (storage.getItem('theme')) setTheme(storage.getItem('theme'))
    else setTheme('lite-theme')
  }, []) // eslint-disable-line

  const outerClasses = classNames('hero', props.outerClasses, selectedTheme, { 'is-fullheight-with-navbar': props.fullheight })
  const innerClasses = classNames('hero-body', props.innerClasses, { 'is-paddingless': props.paddingless, 'align-to-start': props.alignstart, 'hero-centered': props.centered })
  return (
    <>
      <DesktopNavbar handleSwitchTheme={switchTheme} currentTheme={selectedTheme} />
      <div className={outerClasses}>
        <div className={innerClasses}>
          {props.children}
        </div>
        <Footer />
      </div>
    </>
  )
}

export default MainLayout
