import React, { useCallback, useState } from 'react'
import jsPDF from 'jspdf'

const usePdfify = () => {
  const [magic] = useState({
    printing: false,
    element: null,
    pdfOptions: {},
    callback: null
  })

  const [, setMagicUpdater] = useState({})
  const triggerUpdate = () => setTimeout(() => setMagicUpdater({}))

  const renderedCallback = useCallback(async element => {
    if (!element || !magic.callback) return

    const callback = magic.callback
    magic.callback = null

    const doc = new jsPDF({ // eslint-disable-line new-cap
      format: 'letter',
      orientation: 'landscape'
    })

    doc.html(element, {
      ...magic.pdfOptions,
      html2canvas: {
        ...(magic.pdfOptions.html2canvas || {}),
        scale: 0.25
      },
      x: 4,
      y: 4,
      callback: (doc) => {
        console.log(doc.getFontList())

        const pdf = Buffer.from(doc.output('arraybuffer'))
        callback(pdf)
      }
    })
  }, [magic])

  const pdfifyElement = magic.printing
    ? (
      <>
        <div className='use-pdfify'>
          <div ref={renderedCallback}>
            {magic.element}
          </div>
        </div>
      </>
      )
    : <></>

  const pdfify = (element, options = {}) => new Promise((resolve, reject) => {
    // TODO set up a queue system if this ever becomes important
    if (magic.printing) return reject(new Error('already rendering another pdf'))

    magic.printing = true
    magic.element = element
    magic.pdfOptions = options.pdfOptions || {}

    magic.callback = (pdf) => {
      magic.element = null
      magic.printing = false
      triggerUpdate()

      resolve(pdf)
    }

    triggerUpdate()
  })

  return [pdfifyElement, pdfify]
}

export default usePdfify
