import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSync, faAngleRight } from '@fortawesome/pro-light-svg-icons'
import AddToPlaylist from '../AddToPlaylist'
import classNames from 'classnames'

import { useList, useFrankentangel } from '../../../frankentangel'
import Playlist from './Playlist'

import Button from '../../Button'

const Playlists = props => {
  const { currentUser, currentUserId, userContract } = props
  const [Paginator, results] = useList('playlists', 'list')
  const frankentangel = useFrankentangel()
  const [createPlaylistPop, setCreatePlaylistPop] = useState(false)
  const [isCreatingPlaylist, setCreatingPlaylist] = useState(false)
  const [showAddToPlaylist, setAddToPlaylist] = useState({ show: false, trackId: null, playlistId: null })
  const playlistEl = useRef('')

  const playlists = results
    ? results.map((playlist, index) => {
        return (
          <Playlist
            key={`${index}-${playlist.title}`}
            playlist={playlist}
            currentUser={currentUser}
            copyToPlaylist={trackId => setAddToPlaylist({ show: true, trackId, playlistId: playlist.doc.id })}
            indexOffset={index * 10000 + 1}
            userContract={userContract}
          />
        )
      })
    : null

  const createPlaylist = async () => {
    setCreatingPlaylist(true)
    const result = await frankentangel.createDocument('playlists', { owner: currentUserId, name: playlistEl.current.value, isBookmarks: false, lastUpdated: '' })
    setCreatePlaylistPop(false)
    setCreatingPlaylist(false)
    return result
  }

  const createPlaylistContent = playlists.length < 50
    ? (
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Create a new playlist</p>
          <button className='delete' aria-label='close' onClick={() => setCreatePlaylistPop(false)} />
        </header>
        <section className='modal-card-body'>
          <div className='field'>
            <label className='label'>Name</label>
            <div className='control'>
              <input className='input' type='text' ref={playlistEl} />
            </div>
          </div>
        </section>
        <footer className='modal-card-foot'>
          <Button onClick={() => setCreatePlaylistPop(false)} classes={['modal-cancel']}>
            Cancel
          </Button>
          &nbsp;
          <button type='button' className={classNames('button submitButton', { 'is-loading': isCreatingPlaylist })} onClick={createPlaylist}>
            <span>Create</span>
            <FontAwesomeIcon icon={faAngleRight} size='2x' />
          </button>
        </footer>
      </div>
      )
    : (
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Limit reached</p>
          <button className='delete' aria-label='close' onClick={() => setCreatePlaylistPop(false)} />
        </header>
        <section className='modal-card-body'>
          <p>A maximum of 50 playlists are permitted per account.</p>
        </section>
        <footer className='modal-card-foot'>
          <Button onClick={() => setCreatePlaylistPop(false)} classes={['modal-cancel']}>
            Okay
          </Button>
        </footer>
      </div>
      )

  return (
    <div className='explore__playlists-wrapper'>
      <div className='explore__playlists-add-wrapper'>
        <button type='button' onClick={() => setCreatePlaylistPop(true)}><FontAwesomeIcon icon={faPlus} />&nbsp;New Playlist</button>
      </div>
      <div className='explore__playlists-list columns is-multiline is-vcentered'>
        <Paginator spinner={<div className='loadingSpinner'><FontAwesomeIcon icon={faSync} spin size='3x' /></div>}>
          {playlists}
        </Paginator>
      </div>
      <div className={classNames('modal', {
        'is-active': createPlaylistPop
      })}
      >
        <div className='modal-background' onClick={() => setCreatePlaylistPop(false)} />
        {createPlaylistContent}
      </div>
      <AddToPlaylist active={showAddToPlaylist} setActive={setAddToPlaylist} mode='copy' />
    </div>
  )
}

export default Playlists
