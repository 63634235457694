import React from 'react'
import { useLocation } from 'react-router-dom'
import MainLayout from '../containers/MainLayout'

const PasswordResetMessage = props => {
  const location = useLocation()
  const pathName = location.pathname

  return (
    <MainLayout fullheight centered>
      <div className='container'>
        <div className='columns is-centered'>
          <div className='colum is-full'>
            {pathName === '/pwresetcomplete' && <p className='has-text-centered msg-success'>Your password reset was successful.<br />Please check your email for further instructions.</p>}
            {pathName === '/pwresetfail' && <p className='has-text-centered msg-failure'>An error occurred while attempting to reset your password.<br />This may be due to an expired password reset attempt.<br />Please restart the password reset process.</p>}
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default PasswordResetMessage
