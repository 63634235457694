import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'

import MainLayout from '../containers/MainLayout'
import FormWrapper from '../components/FormWrapper'
import FormInputField from '../components/FormInputField'
import Button from '../components/Button'
import SubmitButton from '../components/SubmitButton'
import FormErrorsList from '../components/FormErrorsList'
import FormStatus from '../components/FormStatus'
import useModal from '../components/useModal'
import usePasswordInput from '../components/usePasswordInput'
import { useDocument, useFrankentangel, useSession } from '../frankentangel'

const Settings = props => {
  const [user, setUser] = useState({})
  const [passwordPopup, setPasswordPopup] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)
  // const [downloadCounter, setDownloadCounter] = useState(null)
  const frankentangel = useFrankentangel()
  const history = useHistory()

  const [session] = useSession()
  const [updatingUser] = useDocument('users', session.user)
  const allDownloadsLeft = updatingUser?.station?.map(oneStation => oneStation.contract?.downloadsLeft).filter(a => a).reduce((a, b) => a + b)
  const downloadCounter = [allDownloadsLeft, 'unlimited', '[loading]'][[Infinity, null].indexOf(allDownloadsLeft) + 1]

  const [passwordInput, getPassword, resetPassword] = usePasswordInput()
  const [modal, showModal] = useModal()

  const startPassword = () => {
    resetPassword()
    setPasswordPopup(true)
  }

  const cancelPassword = () => {
    setPasswordPopup(false)
    resetPassword()
  }

  const savePassword = async () => {
    const { password, ready } = getPassword()
    if (!ready) return console.error('Password not ready yet')

    setPasswordLoading(true)

    const result = await frankentangel.updateDocument('users', user.id, { password })

    if (result.ok) {
      setPasswordPopup(false)
      setPasswordLoading(false)
      showModal(
        'Password saved',
        `New password for user ${user.name} successfully saved`,
        [{
          text: 'OK',
          response: 'ok',
          isCancel: true
        }]
      )
    } else {
      const errorMsg = result.error ? (result.error.message || result.error) : 'Unknown Error'
      await showModal(
        'Error',
        `Unknown error while saving password for user ${user.name}: ${errorMsg}`,
        [{
          text: 'OK',
          response: 'ok',
          isCancel: true
        }]
      )
      setPasswordLoading(false)
    }
  }

  const fetchUser = async () => {
    const { session } = await frankentangel.viewSession()
    if (!session.user) return history.push('/')
    const fetchedUser = await frankentangel.viewDocument('users', session.user)
    // if (fetchedUser.station.length !== 0) {
    //   const allDownloadsLeft = fetchedUser.station.map(oneStation => oneStation.contract?.downloadsLeft).filter(a => a).reduce((a, b) => a + b)
    //   setDownloadCounter(allDownloadsLeft === Infinity ? 'unlimited' : allDownloadsLeft)
    // }
    setUser(fetchedUser)
  }

  useEffect(() => {
    fetchUser()
  }, []) // eslint-disable-line

  const submitUserData = async values => {
    if (!values.password || values.password === '') delete values.password
    await frankentangel.updateDocument('users', user.id, values)
    fetchUser()
    return 'User settings saved'
  }

  const downloadsCount = downloadCounter !== null
    ? (
      <div className='contactFormWrapper contactFormWrapper--no-top-margin'>
        <div className='contactFormWrapper__inner-wrapper'>
          <h1 className='contactFormWrapper__form-title'>Music Downloads</h1>
          <p>You have {downloadCounter} downloads remaining.</p>
        </div>
      </div>
      )
    : null

  return (
    <MainLayout alignstart fullheight innerClasses={['home-body']}>
      <Helmet>
        <title>Settings - TAA Music</title>
      </Helmet>
      <div className='container settings-page'>
        <div className='columns is-centered  is-multiline'>
          <div className='column is-7'>
            <div className='contactFormWrapper contactFormWrapper--no-bottom-margin'>
              <div className='contactFormWrapper__inner-wrapper'>
                <h1 className='contactFormWrapper__form-title'>Settings</h1>
                <FormWrapper
                  fields={yup.object({
                    firstName: yup.string().max(128, 'Max 128 chars').required('Required'),
                    lastName: yup.string().max(128, 'Max 128 chars').required('Required'),
                    phone: yup.string().max(255).matches(/^$|^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, 'Invalid phone number'),
                    password: yup.string().min(2, 'Min 2 chars').max(50, 'Max 50 chars')
                  })}
                  initialValuesFrom={user}
                  submit={submitUserData}
                >
                  <FormInputField name='firstName' label='FIRST NAME' />
                  <FormInputField name='lastName' label='LAST NAME' />
                  <FormInputField
                    name='phone'
                    label='PHONE'
                    pattern='\(\d{3}\)[ ]?\d{3}[-]?\d{4}'
                    phoneType
                    type='tel'
                  />
                  <Button onClick={startPassword} classes={['is-uppercase', 'is-size-7', 'has-text-white', 'py-2', 'mt-3', 'features-box__cta']}>Change Password</Button><br />
                  <FormErrorsList />
                  <SubmitButton buttonText='SEND' classes='contactFormWrapper__submit' />
                  <FormStatus />
                </FormWrapper>
              </div>
            </div>
          </div>
          <div className='column is-7'>
            {downloadsCount}
          </div>
        </div>
      </div>
      <div className={classNames('modal', 'frontend-type', {
        'is-active': passwordPopup
      })}
      >
        <div className='modal-background' onClick={cancelPassword} />
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title'>Set Password</p>
            <button className='delete' aria-label='close' onClick={cancelPassword} />
          </header>
          <section className='modal-card-body user-row__password-prompt'>
            {passwordInput}
            <div className={classNames('user-row__password-loading', {
              'user-row__password-loading--enabled': passwordLoading
            })}
            />
          </section>
          <footer className='modal-card-foot'>
            <Button classes={['submitButton', 'mod-disabled']} disabled={!getPassword().ready || passwordLoading} onClick={savePassword}>
              SAVE PASSWORD
              <FontAwesomeIcon icon={['fal', 'angle-right']} size='2x' />
            </Button>
            &nbsp;
            <Button onClick={cancelPassword} classes={['modal-cancel']}>
              CANCEL
            </Button>
          </footer>
        </div>
      </div>
      {modal}
    </MainLayout>
  )
}

export default Settings
