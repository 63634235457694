import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import useForm from './useForm'
import { useList } from '../frankentangel'

const SuggestionList = ({ suggestions, datalistId, listLoading, setListLoading }) => {
  const [_Paginator, results, _error, loading] = useList(suggestions.table, suggestions.action, suggestions.params || {}) // eslint-disable-line no-unused-vars

  if (loading !== listLoading) setTimeout(() => setListLoading(loading), 0)

  return (
    <datalist id={datalistId}>
      {
        results.filter(result => result.doc).map((result, index) => (
          <option value={result.doc} key={index}>{result.doc}</option>
        ))
      }
    </datalist>
  )
}

const FormInputField = props => {
  const { id, name, onBlur, onChange, placeholder, disabled, required, label, classes, type, iconLeft, iconRight, phoneType, pattern, suggestions, readOnly, preDefValue, autoFocus } = props
  const [fieldValue, fieldState, handleFieldChange, handleFieldBlur] = useForm(name)

  const [datalistId] = useState(`suggestion-list-${Math.random().toString(16).slice(2, 10)}`)
  const [listLoading, setListLoading] = useState(false)

  const formatPhoneValue = val => {
    if (val.length < 10) return val
    const eliminationRegExp = /[\s()-]/gi
    const cleansedVal = val.replace(eliminationRegExp, '')
    const formattedVal = ['(', cleansedVal.slice(0, 3), ')', ' ', cleansedVal.slice(3, 6), '-', cleansedVal.slice(6)].join('')
    return formattedVal
  }

  useEffect(() => {
    if (preDefValue) handleFieldChange(name, preDefValue)
  }, []) // eslint-disable-line

  const handleOnBlur = ev => {
    const returnValue = phoneType ? formatPhoneValue(ev.target.value) : ev.target.value
    handleFieldBlur(name, returnValue)
    if (onBlur) onBlur(ev)
  }
  const handleOnChange = ev => {
    const returnValue = phoneType ? formatPhoneValue(ev.target.value) : ev.target.value
    handleFieldChange(name, returnValue)
    if (onChange) onChange(ev)
  }

  const inputProperties = {
    type: type || 'text',
    disabled,
    required,
    className: classNames('input', { 'is-danger': fieldState.state === 'invalid' }, classes),
    placeholder,
    id,
    name: suggestions ? undefined : name,
    pattern,
    readOnly,
    autoFocus,
    value: fieldValue,
    list: suggestions ? datalistId : undefined,
    onChange: ev => handleOnChange(ev),
    onBlur: ev => handleOnBlur(ev)
  }

  let inputMarkup = (
    <>
      <input {...inputProperties} />
      {fieldState.state === 'invalid' && (<p className='help is-danger'>{fieldState.errorMsg}</p>)}
      {suggestions ? <SuggestionList {...{ suggestions, datalistId, listLoading, setListLoading }} /> : undefined}
    </>
  )

  if (label) {
    inputMarkup = (
      <div className='field'>
        <label className='label'>{label}</label>
        <div className={classNames('control', { 'is-loading': listLoading })}>
          <input {...inputProperties} />
        </div>
        {fieldState.state === 'invalid' && (<p className='help is-danger'>{fieldState.errorMsg}</p>)}
        {suggestions ? <SuggestionList {...{ suggestions, datalistId, listLoading, setListLoading }} /> : undefined}
      </div>
    )
  }

  if (iconLeft || iconRight) {
    const wrapperClasses = classNames('control', {
      'has-icons-left': iconLeft,
      'has-icons-right': iconRight,
      'is-loading': listLoading
    })
    inputMarkup = (
      <div className='field'>
        {label && <label className='label'>{label}</label>}
        <div className={wrapperClasses}>
          <input {...inputProperties} />
          {iconLeft && <span className='icon is-small is-left'>{iconLeft && (<span className='icon'><FontAwesomeIcon icon={iconLeft} /></span>)}</span>}
          {iconRight && <span className='icon is-small is-right'>{iconRight && (<span className='icon'><FontAwesomeIcon icon={iconRight} /></span>)}</span>}
        </div>
        {fieldState.state === 'invalid' && (<p className='help is-danger'>{fieldState.errorMsg}</p>)}
        {suggestions ? <SuggestionList {...{ suggestions, datalistId, listLoading, setListLoading }} /> : undefined}
      </div>
    )
  }

  return inputMarkup
}

export default FormInputField
