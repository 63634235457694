import { faExclamationCircle, faSyncAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { useDocument, useSession } from '../frankentangel'
import StationSelector from './StationSelector'
import WeekSelector from './WeekSelector'
import xkcd1179withTZ from '../utils/xkcd1179-with-tz'
import CommercialsTable from './CommercialsTable'
// import WipBanner from './WipBanner'

const CommercialsSystem = () => {
  const selectedStation = useSelector(state => state.commercials.station)
  const selectedWeek = useSelector(state => state.commercials.week)

  const [session, , sessionLoading] = useSession()
  const [user, , userLoading] = useDocument('users', session && session.user)
  const [station, , stationLoading] = useDocument('accounts', selectedStation)
  const [week, weekError, weekLoading] = useDocument('commercial-weeks', selectedWeek)

  if (weekError) console.error('error with week', weekError)

  const isLoading = sessionLoading || userLoading || stationLoading || weekLoading

  return (
    <div className='commercials-system'>
      {/* <WipBanner /> */}
      {
        isLoading
          ? (
            <p className='commercials-system__loading has-text-centered'>
              <FontAwesomeIcon icon={faSyncAlt} spin size='6x' />
            </p>
            )
          : (
              !station
                ? <StationSelector
                    title='Commercials Download'
                    user={user}
                    setAction='commercials.setStation'
                    notice={doc => (
                      (
                        (doc?.weeks?.list || [])
                          .map(weekDoc => weekDoc.doc)
                          .filter(week => week.actualDate.localeCompare(xkcd1179withTZ('America/Chicago', new Date(new Date().getTime() - 7 * 24 * 3600 * 1000))) > 0)
                          .map(week => week.commercialsAvailable)
                          .reduce((a, b) => a || b, false)
                      )
                        ? (
                          <div className='station-selector__station-notice'>
                            <span className='icon'>
                              <FontAwesomeIcon icon={faExclamationCircle} />
                            </span>
                            <span>
                              Commercials available
                            </span>
                          </div>
                          )
                        : undefined
                    )}
                  />
                : (
                    !week
                      ? <WeekSelector
                          title={`Commercials for ${station.callsign}`}
                          user={user}
                          setAction='commercials.setWeek'
                          backAction='commercials.clearStation'
                          system='commercials'
                          notice={doc => (
                            doc.commercialsAvailable
                              ? (
                                <div className='week-selector__week-notice'>
                                  <span className='icon'>
                                    <FontAwesomeIcon icon={faExclamationCircle} />
                                  </span>
                                  <span>
                                    Commercials available
                                  </span>
                                </div>
                                )
                              : undefined
                          )}
                          downloadableOnly
                        />
                      : <CommercialsTable backAction='commercials.clearWeek' />
                  )
            )
      }
    </div>
  )
}

export default CommercialsSystem
